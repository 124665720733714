import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountModel } from '@epione/shared/models/account.model';
import { AccountsGuarantorHttpService } from '@epione/modules/account/services/accounts-guarantor-http.service';
import { catchError, finalize, first, take, tap } from 'rxjs/operators';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { of, Subject, throwError } from 'rxjs';
import { ConfirmDialogService } from '@epione/shared/dialogs/confirm-dialog.service';
import { SuccessDialogService } from '@epione/shared/dialogs/success-dialog.service';

@Component({
    selector: 'epione-associate-account',
    templateUrl: './associate-account.component.html',
    styleUrls: ['./associate-account.component.scss']
})
export class AssociateAccountComponent implements OnInit {

    @Input() account!: AccountModel;
    @ViewChild('guarantorConfirmTmpl') public guarantorConfirmTmpl!: TemplateRef<any>;
    public closure: Function = () => {};
    public guarantorFormGroup!: FormGroup;
    public accountNumberMatch: boolean | null = null;
    public mobileNumberMatch: boolean | null = null;
    public guarantorAccount?: AccountModel;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private loadingStateService: LoadingStateService,
        private accountsGuarantorHttpService: AccountsGuarantorHttpService,
        private successDialogService: SuccessDialogService,
        private errorDialogService: ErrorDialogService,
        private confirmDialogService: ConfirmDialogService
    ) {
        this.initGuarantorForm();
    }

    ngOnInit(): void {
        this.guarantorFormGroup.valueChanges.subscribe({
            next: () => {
                this.accountNumberMatch = (
                    this.guarantorFormGroup.get('guarantor_account_number')?.value
                    && this.guarantorFormGroup.get('guarantor_account_number_confirm')?.value
                    && this.guarantorFormGroup.get('guarantor_account_number')?.value
                    === this.guarantorFormGroup.get('guarantor_account_number_confirm')?.value
                );
                this.mobileNumberMatch = (
                    this.guarantorFormGroup.get('guarantor_mobile_number')?.value
                    && this.guarantorFormGroup.get('guarantor_mobile_number_confirm')?.value
                    && this.guarantorFormGroup.get('guarantor_mobile_number')?.value
                    === this.guarantorFormGroup.get('guarantor_mobile_number_confirm')?.value
                );
            }
        });
    }

    public getAccountDetails() {
        return [this.account.patient.first_name, this.account.patient.last_name, this.account.account_number].filter(Boolean).join(' ');
    }

    public associateAccount(confirmed: boolean = false) {
        if (this.guarantorFormGroup.invalid || !this.accountNumberMatch || !this.mobileNumberMatch) {
            this.guarantorFormGroup.markAllAsTouched();
            return;
        }
        const serverData = {
            account_number: this.guarantorFormGroup.get('guarantor_account_number')?.value,
            mobile_number: this.guarantorFormGroup.get('guarantor_mobile_number')?.value,
            confirmation: confirmed
        };
        this.loadingStateService.start('associate-account');
        this.accountsGuarantorHttpService.associate(this.account.id, serverData)
            .pipe(
                take(1),
                tap(res => {
                    this.successDialogService.showSuccessDialog('Guarantor Added Successfully');
                    this.closure();
                }),
                catchError(err => {
                    if (err.status === 428) {
                        this.guarantorAccount = err.error.guarantor;
                        this.confirmAssociation();
                        return of();
                    } else {
                        this.errorDialogService.showErrorDialogFromResponse(err);
                        return throwError(err);
                    }
                }),
                finalize(() => {
                    this.loadingStateService.end('associate-account');
                })
            ).subscribe();
    }

    private confirmAssociation() {
        this.confirmDialogService.showConfirmDialog(
            '',
            'WARNING',
            null,
            this.guarantorConfirmTmpl,
            '',
            '',
            [
                {
                    title: 'Cancel',
                    action: () => {
                        return true;
                    },
                    buttonClass: 'btn btn-outline-primary mr-1 ml-1'
                },
                {
                    title: 'Confirm',
                    action: () => {
                        this.associateAccount(true);
                        return true;
                    },
                    buttonClass: 'btn btn-primary mr-1 ml-1'
                }
            ]
        );
    }

    private initGuarantorForm() {
        this.guarantorFormGroup = this.fb.group({
            guarantor_account_number: [null, Validators.required],
            guarantor_account_number_confirm: [null, Validators.required],
            guarantor_mobile_number: [null, Validators.required],
            guarantor_mobile_number_confirm: [null, Validators.required],
        });
    }
}
