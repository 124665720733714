import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReportsRoutingModule } from '@epione/modules/reports/reports-routing.module';
import { ReportsComponent } from '@epione/modules/reports/reports.component';


@NgModule({
	declarations: [
		ReportsComponent
	],
	imports: [
		CommonModule,
		ReportsRoutingModule
	]
})
export class ReportsModule {}
