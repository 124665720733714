import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '@epione/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsRoutingModule } from './settings-routing.module';
import { MemberPreferencesComponent } from './member-preferences/member-preferences.component';
import { FinAccountMappingComponent } from './fin-account-mapping/fin-account-mapping.component';
import { MatIconModule } from '@angular/material/icon';
import { MedicalAidPracticeComponent } from './medical-aid-practice/medical-aid-practice.component';
import { BasketComponent } from './basket/basket.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BasketSaveComponent } from './basket/basket-save/basket-save.component';
import { InvoiceModule } from '@epione/modules/invoice/invoice.module';
import { SelectBasketComponent } from './basket/select-basket/select-basket.component';
import { DragDropModule } from '@angular/cdk/drag-drop';



@NgModule({
  declarations: [
    SettingsComponent,
    FinAccountMappingComponent,
    MemberPreferencesComponent,
    MedicalAidPracticeComponent,
    BasketComponent,
    BasketSaveComponent,
    SelectBasketComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    FormsModule,
    NgbDropdownModule,
    InvoiceModule,
    DragDropModule
  ]
})
export class SettingsModule { }
