import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from '@epione/modules/dashboard/dashboard-routing.module';
import { DashboardComponent } from '@epione/modules/dashboard/dashboard.component';


@NgModule({
	declarations: [
		DashboardComponent
	],
	imports: [
		CommonModule,
		DashboardRoutingModule
	]
})
export class DashboardModule {}
