<div class="container-fluid">
    <div class="row mb-4">
        <div class="col-md-6">
            <h1 class="text-epione text-uppercase font-weight-bold">Basket Settings</h1>
            <p>
                Create a new basket, or search an existing basket below.
            </p>
        </div>
        <div class="col">
            <a class="btn btn-primary float-right"
               epioneLoadingState="basket-save"
               routerLink="create"
               routerLinkActive="active"
               role="button">
                + Create Basket
            </a>
        </div>
    </div>
    <div class="row mb-1">
        <div class="input-group col-12">
            <input [formControl]="$searchControl"
                   type="search"
                   class="form-control"
                   placeholder="Search baskets..."
                   epioneLoadingState="basket-save">
            <div class="input-group-append">
                <button (click)="searchBaskets()" type="button" class="btn btn-primary search-basket">
                    <mat-icon [inline]="true">search</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-hover mt-5">
                <thead>
                <tr class="text-epione text-uppercase">
                    <th scope="col" epioneSortable="basketScheme.name" (sort)="sort($event)">Basket Name</th>
                    <th scope="col" epioneSortable="created_at" (sort)="sort($event)">Created</th>
                    <th scope="col" epioneSortable="updated_at" (sort)="sort($event)">Last Updated</th>
                    <th scope="col">Total Line Items</th>
                    <th scope="col">Basket Total Price</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="baskets.length; else basketsLoading">
                    <tr *ngFor="let basket of baskets">
                        <td>{{basket.title}}</td>
                        <td>{{basket.created_at | date: 'yyyy-MM-dd'}}</td>
                        <td>{{basket.updated_at | date: 'yyyy-MM-dd'}}</td>
                        <td>{{ getTotalItems(basket.basketItems) }}</td>
                        <td>{{ getTotalPrice(basket.basketItems) }}</td>
                        <td>
                            <div class="action-dropdown" ngbDropdown>
                                <a class="nav-link" tabindex="0" ngbDropdownToggle id="actionDropdown" role="button">
                                    &bull;&bull;&bull;
                                </a>
                                <div ngbDropdownMenu aria-labelledby="actionDropdown"
                                     class="dropdown-menu dropdown-menu-right">
                                    <a ngbDropdownItem routerLinkActive="active"
                                       [routerLink]="['update', basket.id]">View/Edit
                                    </a>
                                    <a ngbDropdownItem (click)="deleteBasket(basket.id)">Remove</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #basketsLoading>
                    <tr>
                        <td colspan="9">
                            <p class="text-center">{{loading ? 'Loading Settings...' : 'No Settings To Display'}}
                            </p>
                        </td>
                    </tr>
                </ng-template>
                </tbody>
            </table>
        </div>
        <div class="col-12">
            <div class="float-right">
                <epione-pagination [pagination]="pagination" (change)="reload($event)"></epione-pagination>
            </div>
        </div>
    </div>
</div>
