import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { AccountMappingModel } from '@epione/shared/models/account-mapping.model';
import { FinAccountModel } from '@epione/shared/models/fin-account.model';
import { ProviderAccountModel } from '@epione/shared/models/provider-account.model';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { forkJoin, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccountMappingHttpService } from './services/http/account-mapping-http.service';
import { FinAccountHttpService } from './services/http/fin-account-http.service';
import { ProviderAccountHttpService } from './services/http/provider-account-http.service';

@Component({
	selector: 'epione-fin-account-mapping',
	templateUrl: './fin-account-mapping.component.html',
	styleUrls: ['./fin-account-mapping.component.scss']
})
export class FinAccountMappingComponent implements OnInit {

	public loadingStatus: string | null = 'Loading Field Mapping';
	public finAccounts!: FinAccountModel[];
	public providerAccounts!: ProviderAccountModel[];
	public providerAccountsPayable!: ProviderAccountModel[];
	public accountMappings: AccountMappingModel[] = [];

	public mappingForm: FormGroup = this.fb.group({})

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private activeUserService: ActiveUserService,
		private providerAccountService: ProviderAccountHttpService,
		private finAccountService: FinAccountHttpService,
		private accountMappingService: AccountMappingHttpService,
		private errorDialogService: ErrorDialogService
	) { }

	ngOnInit(): void {
		this.refreshData();
	}

	public providerAccountChanged(providerAccount: any, finAccountId: number) {
		if (!providerAccount) {
			this.mappingForm.get(`${finAccountId}`)?.patchValue(null);
		} else {
			let baseObj: any = {
				billing_fin_account_id: finAccountId
			};
			const mapping = this.accountMappings.find(mapping => mapping.billing_fin_account_id === finAccountId)
			if (mapping) {
				baseObj.id = mapping.id;
			}
			this.patchMappingControl(finAccountId, Object.assign(baseObj, providerAccount));
		}
	}

	public submitMappings() {
		this.mappingForm.updateValueAndValidity();
		if (this.mappingForm.invalid) {
			this.mappingForm.markAllAsTouched();
			return;
		}
		this.loadingStatus = 'Saving Account Mappings';
		let submitObservables: Observable<AccountMappingModel>[] = [];
		Object.keys(this.mappingForm.controls).forEach(key => {
			const control = this.mappingForm.get(key);
			if (control) {
				if (control.value.id) {
					submitObservables.push(this.accountMappingService.update(control.value.id, control.value).pipe(take(1)));
				} else {
					submitObservables.push(this.accountMappingService.create(control.value).pipe(take(1)));
				}
			}
		})
		forkJoin(submitObservables).subscribe({
			next: () => {
				this.loadingStatus = 'Validating Account Settings';
				this.activeUserService.validateIntegration().then((res: any) => {
					if (res) {
						// leave here
						this.router.navigate(['/account/list']);
						this.loadingStatus = null;
					} else {
						// stay on current page
						this.refreshData();
					}
				});
			},
			error: err => {
				this.loadingStatus = null;
				this.errorDialogService.showErrorDialogFromResponse(err);
			}
		});
	}

	public searchAccount = (term: string, item: ProviderAccountModel): boolean => {
		return item.provider_name.toLowerCase().includes(term.toLowerCase());
	}

	private createForm() {
		this.finAccounts.forEach(account => {
			if (!this.mappingForm.get(`${account.id}`)) {
				this.addMappingControl(account.id)
			}
		})
	}

	private addMappingControl(accountId: number) {
		this.mappingForm.addControl(`${accountId}`, this.fb.control(null, [Validators.required]));
	}

	private patchForm() {
		this.accountMappings.forEach(mapping => {
			this.mappingForm.get(`${mapping.billing_fin_account_id}`)?.patchValue(mapping)
		})
	}

	private patchMappingControl(finAccountId: number, value: AccountMappingModel) {
		const control = this.mappingForm.get(`${finAccountId}`);
		if (control) {
			control.patchValue(value);
		}
	}

	private refreshData() {
		forkJoin([
			this.accountMappingService.list(),
			this.finAccountService.list(),
			this.providerAccountService.getProviderAccountList()
		]).subscribe(([accountMappings, finAccounts, providerAccounts]) => {
			this.accountMappings = accountMappings;
			this.finAccounts = finAccounts;
			this.providerAccounts = providerAccounts;
			this.providerAccountsPayable = providerAccounts.filter(acc => acc.payable);
			this.createForm();
			this.patchForm();

			this.loadingStatus = null;
		});
	}
}
