import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from "@epione/shared/dialogs/confirm-dialog/confirm-dialog.component";

export interface ConfirmModalAction {
  title: string;
  action: Function;
  buttonClass?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private modalService: NgbModal) { }

  public showConfirmDialog(
    message: string = 'Are you sure?',
    title: string = 'Confirmation',
    subject?: any,
    content?: TemplateRef<any>,
    confirmButtonName?: string,
    cancelButtonName?: string,
    actions?: ConfirmModalAction[]
  ): NgbModalRef {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.subject = subject;
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.confirmButtonName = confirmButtonName;
    modalRef.componentInstance.cancelButtonName = cancelButtonName;
    modalRef.componentInstance.actions = actions;

    return modalRef;
  }
}
