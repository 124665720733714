import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToolbarComponent } from '@epione/shared/components/toolbar/toolbar.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
	declarations: [
		ToolbarComponent
	],
	exports: [
		ToolbarComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		NgbDropdownModule
	]
})
export class ToolbarModule {}
