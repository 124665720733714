import { Injectable } from '@angular/core';
import { MainHttpApiService } from "@epione/shared/services/http/main-http-api.service";
import { MedicalAidSchemeModel } from "@epione/shared/models/main/medical-aid-scheme.model";

@Injectable({
  providedIn: 'root'
})
export class MedicalAidSchemesService extends MainHttpApiService<MedicalAidSchemeModel, MedicalAidSchemeModel[]> {
  protected resourcePath = 'medical-aid/scheme';
}
