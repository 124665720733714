import { Injectable } from '@angular/core';
import { InvoiceModel } from '@epione/shared/models/invoice.model';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of, race, Subject } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { AuthorizeInvoiceModalComponent } from '../components/authorize-invoice-modal/authorize-invoice-modal.component';
import { InvoiceAction, SendInvoiceModalComponent } from '../components/send-invoice-modal/send-invoice-modal.component';
import { TrackPaymentModalComponent } from '../components/track-payment-modal/track-payment-modal.component';
import { InvoiceHttpService } from './http/invoice-http.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceActionService {

  constructor(
    private modalService: NgbModal,
    private loadingStateService: LoadingStateService,
    private invoiceHttpService: InvoiceHttpService
  ) { }

  public authorize(invoice: InvoiceModel) {
    const modalRef: NgbModalRef = this.modalService.open(AuthorizeInvoiceModalComponent, {
      centered: true,
      keyboard: false,
      beforeDismiss: () => false
    });
    const componentRef = (modalRef.componentInstance as AuthorizeInvoiceModalComponent);
    componentRef.invoice = invoice;
    return race([
      // if modal closed
      modalRef.hidden.pipe(map(() => false)),
      // or invoice sent
      componentRef.authorizeInvoice$
    ]).pipe(
      take(1),
      switchMap((authorize: boolean) => {
        if (authorize) {
          this.loadingStateService.start('invoice-authorize');
          return this.invoiceHttpService.authorize(invoice.id).pipe(map(() => true));
        }
        return of(false);
      }),
      finalize(() => {
        this.loadingStateService.end('invoice-authorize');
        if (modalRef) {
          modalRef.close();
        }
      })
    );
  }

  public send(invoice: InvoiceModel) {
    let done = false;
    const modalRef: NgbModalRef = this.modalService.open(SendInvoiceModalComponent, {
      centered: true,
      keyboard: false,
      beforeDismiss: () => done
    });
    const componentRef = (modalRef.componentInstance as SendInvoiceModalComponent);
    componentRef.invoice = invoice;
    return race<InvoiceAction>([
      // if modal closed
      modalRef.hidden.pipe(map(() => {
        return { action: 'cancel', sendClaimEmail: false }
      })),
      // or invoice sent
      componentRef.invoiceAction$
    ]).pipe(
      take(1),
      switchMap((send: InvoiceAction) => {
        if (send.action === 'send') {
          this.loadingStateService.start('invoice-send');
          return this.invoiceHttpService.send(invoice.id, send.sendClaimEmail).pipe(map(() => true));
        }
        if (send.action === 'print') {
          this.loadingStateService.start('invoice-send');
          return this.invoiceHttpService.download(invoice.id, send.sendClaimEmail).pipe(
            switchMap(response => {
              const sub = new Subject<void>();
              const blobUrl = URL.createObjectURL(response);

              // window.open(blobUrl, '_blank');
              // download
              // const anchor = document.createElement('a');
              // anchor.href = bloblUrl;
              // anchor.download = bloblUrl

              // print
              const iframe = document.createElement('iframe');
              iframe.style.display = 'none';
              iframe.src = blobUrl;
              document.body.appendChild(iframe);
              iframe.onload = () => {
                setTimeout(() => {
                  iframe.focus();
                  if (iframe.contentWindow) {
                    const clean = setTimeout(() => iframe.remove(), 5 * 60 * 1000); // 5min timer to clean out the iframe when no action happens
                    iframe.contentWindow.onafterprint = () => {
                      clearTimeout(clean);
                      iframe.remove();
                    }
                    iframe.contentWindow.print();
                  } else {
                    window.open(blobUrl);
                  }
                  sub.next();
                });
              };
              return sub.asObservable();
            })
          );
        }
        return of(false);
      }),
      finalize(() => {
        done = true;
        this.loadingStateService.end('invoice-send');
        if (modalRef) {
          modalRef.close();
        }
      })
    );
  }

  public trackPayment(invoice: InvoiceModel) {
    const modalRef: NgbModalRef = this.modalService.open(TrackPaymentModalComponent, {
      centered: true,
      keyboard: false,
      beforeDismiss: () => false
    });
    const componentRef = (modalRef.componentInstance as TrackPaymentModalComponent);
    componentRef.invoice = invoice;
    return race([
      // if modal closed
      modalRef.hidden.pipe(map(() => false)),
      // or invoice sent
      componentRef.complete$
    ]).pipe(
      take(1)
    );
  }
}
