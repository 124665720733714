import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from "@epione/shared/dialogs/error-dialog/error-dialog.component";
import { SanitizerErrorMessageService } from '../services/sanitizer-error-message.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {

  constructor(
    private modalService: NgbModal,
    private sanitizerErrorMessage: SanitizerErrorMessageService
  ) { }

  public showErrorDialog(messages: string[] | string): NgbModalRef {
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    const modalRef = this.modalService.open(ErrorDialogComponent);
    modalRef.componentInstance.messages = messages;

    return modalRef;
  }

  public showErrorDialogFromResponse(res: any): NgbModalRef {
    const messages = this.sanitizerErrorMessage.sanitizeMessage(res);
    return this.showErrorDialog(messages);
  }
}
