<div class="form-group m-0">
    <label for="account" class="text-epione">
        Please select the account record for which you intend to create an invoice
    </label>
    <ng-select placeholder="Search For Account Record..."
               [items]="accounts$ | async"
               id="account"
               [(ngModel)]="account"
               [loading]="accountsLoading"
               [typeahead]="accountSearchTerm$"
               epioneLoadingState="invoice-save">
        <ng-template ng-label-tmp let-item="item">
            {{item.label}}&nbsp;
            <span class="text-muted">[&nbsp;{{item.meta}}&nbsp;]</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            {{item.label}}<br>
            <span class="text-muted">[&nbsp;{{item.meta}}&nbsp;]</span>
        </ng-template>
        <ng-template ng-footer-tmp>
            <span class="text-muted">
                {{accountPagination ? (accountPagination.total - accountPagination.count) : 0}} More
                Records
            </span>
        </ng-template>
    </ng-select>
</div>