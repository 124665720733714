import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { InvoiceStatusId } from '../types/invoice-status';

@Directive({
  selector: '[epioneInvoiceDraft], [epioneInvoiceSubmitted], [epioneInvoiceAuthorized], [epioneInvoicePaid], [epioneInvoiceVoided], [epioneInvoiceIncomplete], [epioneInvoiceComplete]'
})
export class InvoiceStatusDirective {
  private hasView: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set epioneInvoiceDraft(input: any) {
    const [status, defaultvisible] = this.parseInput(input);
    if (typeof status === 'number') {
      if ([InvoiceStatusId.DRAFT].includes(status) && !this.hasView) {
        this.show();
      } else if (this.hasView) {
        this.hide();
      }
    } else {
      this.resolveDefault(defaultvisible);
    }
  }

  @Input()
  set epioneInvoiceSubmitted(input: any) {
    const [status, defaultvisible] = this.parseInput(input);
    if (typeof status === 'number') {
      if (status === InvoiceStatusId.SUBMITTED && !this.hasView) {
        this.show();
      } else if (this.hasView) {
        this.hide();
      }
    } else {
      this.resolveDefault(defaultvisible);
    }
  }

  @Input()
  set epioneInvoiceAuthorized(input: any) {
    const [status, defaultvisible] = this.parseInput(input);
    if (typeof status === 'number') {
      if (status === InvoiceStatusId.AUTHORISED && !this.hasView) {
        this.show();
      } else if (this.hasView) {
        this.hide();
      }
    } else {
      this.resolveDefault(defaultvisible);
    }
  }

  @Input()
  set epioneInvoicePaid(input: any) {
    const [status, defaultvisible] = this.parseInput(input);
    if (typeof status === 'number') {
      if (status === InvoiceStatusId.PAID && !this.hasView) {
        this.show();
      } else if (this.hasView) {
        this.hide();
      }
    } else {
      this.resolveDefault(defaultvisible);
    }
  }

  @Input()
  set epioneInvoiceVoided(input: any) {
    const [status, defaultvisible] = this.parseInput(input);
    if (typeof status === 'number') {
      if (status === InvoiceStatusId.VOIDED && !this.hasView) {
        this.show();
      } else if (this.hasView) {
        this.hide();
      }
    } else {
      this.resolveDefault(defaultvisible);
    }
  }

  @Input()
  set epioneInvoiceIncomplete(input: any) {
    const [status, defaultvisible] = this.parseInput(input);
    if (typeof status === 'number') {
      if ([
        InvoiceStatusId.DRAFT,
        InvoiceStatusId.SUBMITTED
      ].includes(status) && !this.hasView) {
        this.show();
      } else if (this.hasView) {
        this.hide();
      }
    } else {
      this.resolveDefault(defaultvisible);
    }
  }

  @Input()
  set epioneInvoiceComplete(input: any) {
    const [status, defaultvisible] = this.parseInput(input);
    if (typeof status === 'number') {
      if ([
        InvoiceStatusId.AUTHORISED,
        InvoiceStatusId.PAID,
        InvoiceStatusId.VOIDED
      ].includes(status) && !this.hasView) {
        this.show();
      } else if (this.hasView) {
        this.hide();
      }
    } else {
      this.resolveDefault(defaultvisible);
    }
  }

  private show() {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }

  private hide() {
    this.viewContainer.clear();
    this.hasView = false;
  }

  private parseInput(input: any): [number | null, boolean] {
    let status, defaultVisible;
    if (Array.isArray(input)) {
      [status, defaultVisible] = input;
    } else {
      [status, defaultVisible] = `${input ? input : ''}`.split(',');
    }
    return [isNaN(parseInt(status, 10)) ? null : parseInt(status, 10), !!defaultVisible];
  }

  private resolveDefault(visible: boolean) {
    if (visible && !this.hasView) {
      this.show();
    } else if (!visible && this.hasView) {
      this.hide();
    }
  }
}
