<ng-container *ngIf="!loading; else loadingTmpl">
    <epione-toolbar></epione-toolbar>
    <div class="app-container mx-3" [ngClass]="{ 'no-padding': noPadding }">
        <router-outlet></router-outlet>
        <span id="app-version">{{ appVersion }}</span>
    </div>
</ng-container>
<ng-template #loadingTmpl>
    <div class="loading">
        <loader message="Authenticating Your Session"></loader>
    </div>
</ng-template>