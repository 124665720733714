import { Injectable } from '@angular/core';
import { InvoiceModel } from '@epione/shared/models/invoice.model';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoiceHttpService extends BillingHttpApiService<InvoiceModel, PaginatedResponse<InvoiceModel>> {
  protected resourcePath = "invoices";

  /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public authorize(invoiceId: number): Observable<void> {
    return this.http.post<void>(this.buildAction('{:invoiceId}/authorize', { invoiceId }), '', this.buildOptions({})).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param invoiceId
   * @param sendClaimEmail
   * @returns
   * @memberof HttpApiService
   */
  public send(invoiceId: number, sendClaimEmail: boolean): Observable<void> {
    return this.http.post<void>(this.buildAction('{:invoiceId}/send', { invoiceId }), { sendToMedicalAid: `${sendClaimEmail}` },
        this.buildOptions({})).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }

  /**
   * @param invoiceId
   * @param sendClaimEmail
   * @returns
   * @memberof HttpApiService
   */
  public download(invoiceId: number, sendClaimEmail: boolean): Observable<Blob> {
    return this.http.get(this.buildAction('{:invoiceId}', { invoiceId }), {
      headers: { 'Accept': 'application/pdf' },
      responseType: 'blob',
      params: {
        sendToMedicalAid: `${sendClaimEmail}`
      }
    }).pipe(
      catchError(err => {
        return throwError(this.handleValidationErrors(err));
      })
    );
  }
}
