<div class="modal-header">
    <h1 class="modal-title text-epione font-weight-bold" id="modal-basic-title">Add Basket</h1>
</div>
<div class="modal-body">
    <div class="row mb-3">
        <div class="input-group col-12">
            <ng-select placeholder="Search baskets..."
                       epioneLoadingState="basket-select"
                       [items]="baskets"
                       id="basket"
                       [isOpen]="selectOpen"
                       [(ngModel)]="selectedBasket"
                       (ngModelChange)="selectOpen = false"
                       [loading]="basketsLoading"
                       [typeahead]="basketSearchTerm$">
                <ng-template ng-label-tmp let-item="item">
                    {{item?.title}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    {{item?.title}}
                </ng-template>
                <ng-template ng-footer-tmp>
                    <span class="text-muted">
                        {{pagination ? (pagination.total - pagination.count) : 0}} More Records
                    </span>
                </ng-template>
            </ng-select>
            <div class="input-group-append">
                <button (click)="searchBaskets()" type="button" class="btn btn-primary search-basket">
                    <mat-icon [inline]="true">search</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="selectedBasket; else noBaskets">
        <div class="table-responsive basketBoxSelected">
            <table class="invoice-table table">
                <thead>
                    <tr class="text-epione text-uppercase">
                        <th colspan="3">Basket Line Items Preview</th>
                    </tr>
                    <tr class="text-epione text-uppercase">
                        <th>Procedure Code</th>
                        <th>Nappi Code</th>
                        <th>ICD10 Code</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let basketItem of selectedBasket.basketItems">
                        <tr>
                            <td>
                                <strong>[ {{ basketItem.tariff_code_txt }} ]</strong>
                            </td>
                            <td>
                                <strong>[ {{ basketItem.nappiCode?.product_code }} ]</strong>
                                {{ basketItem.nappiCode?.full_product_name }}
                            </td>
                            <td>
                                <ng-container *ngIf="basketItem.icd10Codes?.length">
                                    <div *ngFor="let icd10code of basketItem.icd10Codes">
                                        <strong>[ {{ icd10code.code }} ]</strong> {{ icd10code.description }}
                                    </div>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>
    <ng-template #noBaskets>
        <div class="basketBoxNonSelected text-center">
            <label class="preview-text">No Basket Selected</label>
        </div>
    </ng-template>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary select-basket-btn" (click)="activeModal.close('done')">
        Cancel
    </button>
    <button type="button" class="btn btn-primary select-basket-btn" (click)="addBasket()">Add</button>
</div>