import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccountsHttpService } from '@epione/modules/account/services/accounts-http.service';
import { AccountModel } from '@epione/shared/models/account.model';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

interface AccountSearchResult extends AccountModel {
  isActive: boolean;
  label: string;
  meta: string;
}

@Component({
  selector: 'epione-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectAccountComponent), multi: true }
  ]
})
export class SelectAccountComponent implements OnInit, ControlValueAccessor {

  @Input() accountId?: number;
  @Input() practitionerId?: number;
  @Output() loaded: EventEmitter<AccountModel | null> = new EventEmitter<AccountModel | null>();
  public accountSearchTerm$: Subject<string> = new Subject<string>();
  public accountsLoading: boolean = false;
  public accounts$!: Observable<AccountSearchResult[]>;
  public accountPagination!: Pagination;
  private _account: AccountModel | null = null;
  constructor(
    private accountHttpService: AccountsHttpService
  ) { }

  ngOnInit(): void {
    const include = 'patient.photo,patient.patientFlags,medicalAidOption.medicalAidPlan.medicalAidScheme';
    this.accounts$ = concat<AccountSearchResult[]>(
      this.accountId ? this.accountHttpService.find(this.accountId, {
        params: { include }
      }).pipe(
        map(acc => [{
          isActive: true,
          label: [`(ACC: ${acc.account_number})`, acc.patient.first_name, acc.patient.last_name].filter(Boolean).join(' '),
          meta: [acc.email, acc.mobile_number].filter(Boolean).join(', '),
          ...acc
        }]),
        tap(([account]) => this.account = account)
      ) : of([]), // default items
      this.accountSearchTerm$.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => this.accountsLoading = true),
        switchMap(term => this.accountHttpService.list({
          params: {
            search: term,
            per_page: '20',
            include,
            'filter[practitioner_has_access]': `${this.practitionerId}`
          }
        }).pipe(
          map(res => {
            this.accountPagination = res.meta.pagination;
            return res.data.map(acc => ({
              isActive: true,
              label: [`(ACC: ${acc.account_number})`, acc.patient.first_name, acc.patient.last_name].filter(Boolean).join(' '),
              meta: [acc.email, acc.mobile_number].filter(Boolean).join(', '),
              ...acc
            }));
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.accountsLoading = false)
        ))
      )
    ).pipe(
      map(data => data.reduce<AccountSearchResult[]>(
        (out, row) => {
          // remove duplicates
          if (!out.some(({ id, }) => id === row.id)) {
            out.push(row);
          }
          return out;
        }, [])
      ),
      tap(() => {
        this.loaded.emit(this.account);
      })
    );
    setTimeout(() => this.accountSearchTerm$.next(''), 0);
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  get account(): AccountModel | null {
    return this._account;
  }

  set account(val: AccountModel | null) {  // this value is updated by programmatic changes
    if (val !== undefined && this._account !== val) {
      this._account = val
      this.onChange(val)
      this.onTouch(val)
    }
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.account = value
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }
}
