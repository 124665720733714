<div class="form-group m-0">
    <label for="practitioner" class="text-epione">
        First, please select The Practitioner for whom you are creating this invoice
    </label>
    <ng-select placeholder="Search For Practitioner..."
               [items]="practitioners$ | async"
               id="practitioner"
               [(ngModel)]="practitioner"
               [loading]="practitionersLoading"
               [typeahead]="practitionerSearchTerm$"
               epioneLoadingState="invoice-save">
        <ng-template ng-label-tmp let-item="item">
            {{item.label}}&nbsp;
            <span class="text-muted">[&nbsp;{{item.meta}}&nbsp;]</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            {{item.label}}<br>
            <span class="text-muted">[&nbsp;{{item.meta}}&nbsp;]</span>
        </ng-template>
        <ng-template ng-footer-tmp>
            <span class="text-muted">
                {{practitionerPagination ? (practitionerPagination.total - practitionerPagination.count) : 0}} More
                Records
            </span>
        </ng-template>
    </ng-select>
</div>