import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountListComponent } from '@epione/modules/account/account-list/account-list.component';
import { AccountRoutingModule } from '@epione/modules/account/account-routing.module';
import { AccountSaveComponent } from '@epione/modules/account/account-save/account-save.component';
import { AccountViewComponent } from '@epione/modules/account/account-view/account-view.component';
import { AccountComponent } from '@epione/modules/account/account.component';
import { SharedModule } from '@epione/shared/shared.module';
import { NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AssociateAccountComponent } from './account-save/associate-account/associate-account.component';

@NgModule({
	declarations: [
		AccountComponent,
		AccountSaveComponent,
		AccountListComponent,
		AccountViewComponent,
  		AssociateAccountComponent
	],
	imports: [
		CommonModule,
		AccountRoutingModule,
		SharedModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgbDatepickerModule,
		NgbDropdownModule,
		FormsModule,
		NgbTooltipModule
	],
	providers: []
})
export class AccountModule { }
