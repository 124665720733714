<div class="modal-header">
    <h4 class="modal-title mb-0 text-primary">Authorize Invoice {{ invoice?.invoice_number}}</h4>
</div>
<div class="modal-body text-center">
    <p>
        <strong>NOTICE:</strong> Once you have authorized this invoice, you can no longer make changes.
        <br><br>
        Do you wish to authorize this invoice now?
    </p>
    <button type="button" class="btn btn-outline-primary mx-2 w-25" (click)="modal.close()"
            epioneLoadingState="invoice-authorize">Cancel</button>
    <button type="button" class="btn btn-primary mx-2 w-25" (click)="authorizeInvoice$.next(true)"
            epioneLoadingState="invoice-authorize">Authorize</button>
</div>