import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BasketModel } from '@epione/shared/models/basket.model';
import { BasketHttpService } from '@epione/modules/settings/basket/services/basket-http.service';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { SortableDirective, SortEvent } from '@epione/shared/directives/sortable.directive';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { SuccessDialogService } from '@epione/shared/dialogs/success-dialog.service';
import { Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BasketItemModel } from '@epione/shared/models/basket-item.model';
import { CurrencyModel } from '@epione/shared/models/currency.model';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'epione-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {

    @ViewChildren(SortableDirective) sortableColumns!: QueryList<SortableDirective>;

    public baskets!: BasketModel[];
    public loading: boolean = true;
    public pagination?: Pagination;
    public filters!: { [key: string]: string | null | Date | Array<string | null | Date> };
    public order: SortEvent = { column: 'updated_at', direction: 'desc' };
    public search: string = '';
    public currency: CurrencyModel;
    public $searchControl: FormControl = new FormControl('')

    private options: any = {
        page: 1,
        per_page: 15,
        include: 'basketItems'
    };
    private reload$: Subject<void> = new Subject<void>();
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private errorDialogService: ErrorDialogService,
        private successDialogService: SuccessDialogService,
        private basketHttpService: BasketHttpService,
        private activeUserService: ActiveUserService,
        private loadingStateService: LoadingStateService,
    ) {
        this.resetFilters();
        this.currency = this.activeUserService.currency as CurrencyModel;
    }

    ngOnInit(): void {
        this.reload$.pipe(
            takeUntil(this.unsubscribe$),
            switchMap(() => this.loadData())
        ).subscribe();
        this.reload();
        this.$searchControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                tap((term) => this.search = term)
            ).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public resetFilters() {
        this.filters = {
            // invoice_number: null,
            updated_at: [null, null],
            created_at: [null, null]
        };
    }

    public sort(event: SortEvent) {
        // reset other columns
        this.sortableColumns.filter(s => !s.is(event)).forEach(s => s.reset());
        this.order = event;
        this.reload();
    }

    public reload(page?: number) {
        if (page) {
            this.options.page = page;
        }
        this.loading = true;
        // clear state
        this.baskets = [];
        this.pagination = undefined;
        this.reload$.next();
    }

    loadData() {
        return this.basketHttpService.list({
            params: {
                ...this.options,
                ...(this.search !== '' ? { search: this.search } : {}),
                ...{ order: this.order.column + ',' + this.order.direction }
            }
        }).pipe(
            finalize(() => this.loading = false),
            take(1),
            tap((res) => {
                this.baskets = res.data;
                this.pagination = res.meta.pagination;
            })
        );
    }

    public getTotalItems(basketItems: BasketItemModel[]) {
        return basketItems.length === 1 ? '1 item' : `${ basketItems.length } items`;
    }

    public getTotalPrice(basketItems: BasketItemModel[]) {
        return this.currency.alphabetic_code + ' ' + (basketItems.reduce<number>((total: number, item: BasketItemModel) => {
            return total + (item.unit_amount * item.quantity);
        }, 0.0) / 100.0).toFixed(2);
    }

    public searchBaskets() {
        if (this.search === '') {
            return;
        }
        this.loadingStateService.start('basket-save');
        this.loadData().pipe(tap(() => this.loadingStateService.end('basket-save'))).subscribe();
    }

    public deleteBasket(id: number) {
        this.loadingStateService.start('basket-save');
        this.basketHttpService.delete(id)
            .pipe(
                take(1),
                catchError(err => {
                    this.errorDialogService.showErrorDialogFromResponse(err);
                    return throwError(err);
                }),
                finalize(() => this.loadingStateService.end('basket-save')),
                tap(res => {
                    this.successDialogService.showSuccessDialog(res.message as string);
                    const idx = this.baskets.findIndex(bs => bs.id === id);
                    this.baskets.splice(idx, 1);
                })
            ).subscribe();
    }
}
