import { Component, OnInit } from '@angular/core';
import { APP_CONFIG } from '@epione/shared/config/common.config';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { USER_ROLE_ID } from '../../config/roles.config';

@Component({
  selector: 'epione-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public mainSystemLink: string = APP_CONFIG.APP_URL.main;
  constructor(private activeUserService: ActiveUserService) { }

  ngOnInit(): void {
  }

  get showMenu() {
    return this.activeUserService.validAccountSetup;
  }

  get canAccessSettings() {
    if (this.activeUserService.user?.role?.id === USER_ROLE_ID.PRIMARY_CARE_NURSE) {
      return this.activeUserService.user?.profile.perm_billing;
    }
    return true;
  }
}
