import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Icd10HttpService } from './http/icd10-http.service';
import { NappiHttpService } from './http/nappi-http.service';
import { BillablePreset, BILLABLE_PRESETS } from './presets';

export interface LineItemObject {
  id?: any;
  service_date?: any;
  line_item_type_id?: any;
  tariff_code_txt?: any;
  tariff_code_id?: any;
  icd10_codes?: any[];
  nappi_code_id?: any;
  description?: any;
  quantity?: any;
  unit_amount?: any;
  discount_rate?: number;
}

export interface BillablePresetItem {
  line_item_type_id: null | number;
  tariff_code_txt: null | string;
  // tariff_code_id: null|number;
  icd10_codes: string[];
  nappi_code_id: null | string;
  description: null | string;
  quantity: number;
  unit_amount: number;
}



@Injectable({
  providedIn: 'root'
})
export class BillablePresetService {

  constructor(
    private icd10HttpService: Icd10HttpService,
    private nappiHttpService: NappiHttpService,
  ) { }

  async getLineItems(alias: BillablePreset): Promise<LineItemObject[]> {
    const lineItems = BILLABLE_PRESETS[alias];

    await this.populateLineItemData(lineItems);

    return lineItems;
  }

  async populateLineItemData(lineItems: LineItemObject[]): Promise<LineItemObject[]> {

    const nappiCodesPresent = Array.from(
      new Set( // unique items
        lineItems.reduce((codes: string[], item: LineItemObject) => {
          return [...codes, ...([item.nappi_code_id] || [])];
        }, [])
      )
    ).filter(Boolean); // ignore empty / null values
    const icd10CodesPresent = Array.from(
      new Set( // unique items
        lineItems.reduce((codes: string[], item: LineItemObject) => {
          return [...codes, ...(item.icd10_codes || [])];
        }, [])
      )
    ).filter(Boolean); // ignore empty / null values

    // load the codes that are present to pre-populate the data
    const [nappiCodes, icd10Codes] = await forkJoin([
      nappiCodesPresent.length
        ? this.nappiHttpService.list({
          params: {
            limit: `${nappiCodesPresent.length}`,
            include: 'nappiManufacturer',
            'filter[product_code]': nappiCodesPresent.join(',')
          }
        }).pipe(map(({ data }) => data))
        : of([]),
      icd10CodesPresent.length
        ? this.icd10HttpService.list({
          params: {
            limit: `${icd10CodesPresent.length}`,
            include: 'icd103Code.icd10Group.icd10Chapter',
            'filter[code]': icd10CodesPresent.join(',')
          }
        }).pipe(map(({ data }) => data))
        : of([])
    ]).toPromise();

    // apply all the codes to the line items, remove any that were not matched
    lineItems.forEach(lineItem => {
      if (lineItem.icd10_codes) {
        lineItem.icd10_codes = lineItem.icd10_codes.map(icd10 => {
          return icd10Codes.find(v => v.code === icd10) || null;
        }).filter(Boolean) // remove not matched presets
      }
      if (lineItem.nappi_code_id) {
        lineItem.nappi_code_id = nappiCodes.find(v => v.product_code === lineItem.nappi_code_id) || null;
      }
    })

    return lineItems;
  }
}
