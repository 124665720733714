<div class="form-group m-0">
    <div class="form-control overflow-hidden pseudo-control" [attr.disabled]="disabled"
         (click)="openModal(nappiSelectModal)">
        <ng-container *ngIf="codeValue; else noValue">
            <strong>[&nbsp;{{codeValue?.product_code}}&nbsp;]</strong>&nbsp;{{codeValue?.full_product_name}}
        </ng-container>
        <ng-template #noValue>
            <span class="text-muted">Select NAPPI Code...</span>
        </ng-template>
    </div>
</div>
<ng-template #nappiSelectModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select NAPPI Code</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <ng-select placeholder="Search For NAPPI Code ..."
                       [items]="codes$ | async"
                       id="code"
                       [(ngModel)]="codeValue"
                       (ngModelChange)="closeModal(modal, $event)"
                       [loading]="codesLoading"
                       [typeahead]="codeSearchTerm$"
                       [disabled]="!!disabled"
                       epioneLoadingState="invoice-save">
                <ng-template ng-label-tmp let-item="item">
                    <strong>[&nbsp;{{item?.product_code}}&nbsp;]</strong>&nbsp;{{item?.full_product_name}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <strong>[&nbsp;{{item?.product_code}}&nbsp;]</strong>&nbsp;{{item?.full_product_name}}
                    ({{item?.catalogue_number}})
                    <div class="text-muted">
                        [{{item?.nappiManufacturer?.product_source_code}}]
                        {{item?.nappiManufacturer?.product_source_name}}
                    </div>
                </ng-template>
                <ng-template ng-footer-tmp>
                    <span class="text-muted">
                        {{codePagination ? (codePagination.total - codePagination.count) : 0}} More
                        Records
                    </span>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('done')">Done</button>
    </div>
</ng-template>