export enum RoleAlias {
    PM = 'practice_manager',
    GP = 'general_practitioner',
    SP = 'specialist',
    PCN = 'primary_care_nurse'
}

export enum RoleId {
    PM = 7,
    GP = 5,
    SP = 4,
    PCN = 11
}