import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input('message') message?: string | null;
  @Input('primary') primary?: string | null;
  @Input('secondary') secondary?: string | null;
  constructor() { }

  ngOnInit(): void {
  }

  get style() {
    return {
      ...(this.secondary ? { 'border-color': this.secondary } : {}),
      ...(this.primary ? { 'border-left-color': this.primary } : {})
    };
  }

}
