import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceListComponent } from '@epione/modules/invoice/invoice-list/invoice-list.component';
import { InvoiceSaveComponent } from '@epione/modules/invoice/invoice-save/invoice-save.component';
import { InvoiceViewComponent } from '@epione/modules/invoice/invoice-view/invoice-view.component';
import { InvoiceComponent } from '@epione/modules/invoice/invoice.component';
import { AuthGuard } from '@epione/shared/guards/auth.guard';

const routes: Routes = [
	{
		path: 'invoice',
		component: InvoiceComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', redirectTo: '/list', pathMatch: 'full' },
			{
				path: 'list',
				component: InvoiceListComponent
			}, {
				path: 'create',
				component: InvoiceSaveComponent
			}, {
				path: 'update/:id',
				component: InvoiceSaveComponent
			}
			// Don't need view right now, can view on edit
			/* , {
				path: 'view/:id',
				component: InvoiceViewComponent
			} */
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class InvoiceRoutingModule { }
