import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BasketModel } from '@epione/shared/models/basket.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { SuccessDialogService } from '@epione/shared/dialogs/success-dialog.service';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { ICD10CodeModel } from '@epione/shared/models/codes/icd10.model';
import { of, throwError } from 'rxjs';
import { LineItemObject } from '@epione/modules/invoice/services/billable-preset.service';
import { BasketHttpService } from '../services/basket-http.service';
import { CurrencyModel } from '@epione/shared/models/currency.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'epione-basket-save',
    templateUrl: './basket-save.component.html',
    styleUrls: ['./basket-save.component.scss']
})
export class BasketSaveComponent implements OnInit {

    public action: 'create' | 'update' = 'create';
    public basketLoaded: boolean = false;
    public basketForm!: FormGroup;
    public basketData?: BasketModel;
    public loadingStatus: string | null = 'Loading Basket Data';
    public totalDiscount: number = 0;
    public currency: CurrencyModel;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private activeUserService: ActiveUserService,
        private loadingStateService: LoadingStateService,
        private successDialogService: SuccessDialogService,
        private errorDialogService: ErrorDialogService,
        private basketHttpService: BasketHttpService
    ) {
        this.currency = this.activeUserService.currency as CurrencyModel;
        this.initBasketForm();
    }

    ngOnInit(): void {
        this.route.params.subscribe({
            next: res => {
                if ('id' in res && !!res.id) {
                    this.action = 'update';
                    this.init(res.id);
                } else {
                    this.basketLoaded = true; // nothing to load
                    this.loadingStatus = null;
                }
            }
        });
    }

    get basketItemsFormArray(): FormArray {
        return this.basketForm.get('basket_items') as FormArray;
    }

    get total(): number {
        return (this.basketForm.get('basket_items') as FormArray).controls.reduce<number>((total: number, control: AbstractControl) => {
            return total + this.getLineAmount(control);
        }, 0.0);
    }

    public drop(event: CdkDragDrop<string[]>) {
        const currentGroup = this.basketItemsFormArray.at(event.previousIndex);
        this.basketItemsFormArray.removeAt(event.previousIndex);
        this.basketItemsFormArray.insert(event.currentIndex, currentGroup);
    }

    public getLineAmount(control: AbstractControl): number {
        const value = (control as FormGroup).getRawValue();
        return value.unit_amount * value.quantity;
    }

    public addLineItem(data: LineItemObject = {}): FormGroup {
        const control = this.fb.group({
            id: [data['id'] || null],
            tariff_code_txt: [data['tariff_code_txt'] || null],
            // tariff_code_id: [data['tariff_code_id'] || null], // @TODO: when we have tariffs
            icd10_codes: [data['icd10_codes'] || null],
            nappi_code_id: [data['nappi_code_id'] || null],
            quantity: [data['quantity'] || 1, Validators.required],
            unit_amount: [data['unit_amount'] || null, Validators.required]
        });

        (this.basketForm.get('basket_items') as FormArray).push(control);
        return control;
    }

    public removeLineItem(position: number): void {
        (this.basketForm.get('basket_items') as FormArray).removeAt(position);
    }

    public async submitBasket() {
        if (this.basketForm.invalid) {
            this.basketForm.markAllAsTouched();
            return;
        }

        const basketData = this.basketForm.getRawValue();

        this.loadingStateService.start('basket-save');

        // clear id values if create
        if (this.action === 'create') {
            delete basketData.id;
            basketData.basket_items.forEach((bi: any) => delete bi['id']);
        }

        const enforceID = (item?: null | number | { id?: number }) => {
            if (item && typeof item !== 'number' && typeof item['id'] !== 'undefined') {
                return item.id;
            }
            return item;
        };
        basketData.basket_items = basketData.basket_items.map((item: any, idx: number) => {
            return {
                ...item,
                tariff_code_id: enforceID(item.tariff_code_id),
                icd10_codes: item.icd10_codes ? item.icd10_codes.map((v: number | ICD10CodeModel) => enforceID(v)) : [],
                nappi_code_id: enforceID(item.nappi_code_id),
                order: idx
            };
        });
        console.log(basketData);

        const actions = {
            create: () => this.basketHttpService.create(basketData),
            update: () => this.basketHttpService.update(basketData.id, basketData)
        };

        actions[this.action]().pipe(
            switchMap((basketRes: BasketModel) => {
                return of(false);
            }),
            take(1),
            catchError(err => {
                this.errorDialogService.showErrorDialogFromResponse(err);
                return throwError(err);
            }),
            finalize(() => this.loadingStateService.end('basket-save')),
            tap((sent) => this.successDialogService.showSuccessDialog(`Basket ${this.action === 'create' ? 'Created' : 'Saved'} Successfully`))
        ).subscribe({
            next: () => {
                this.router.navigate(['/settings/basket']);
            }
        });
    }

    private initBasketForm() {
        this.basketForm = this.fb.group({
            id: [null],
            title: [null, Validators.required],
            basket_items: this.fb.array([], [Validators.required])
        });
    }

    private patchForm(basket: BasketModel) {
        this.basketData = basket;
        this.basketForm.patchValue({
            id: basket.id,
            title: basket.title
        });
        if (basket.basketItems) {
            basket.basketItems.sort((a, b) => a.order > b.order ? 1 : (b.order > a.order ? -1 : 0)).forEach(item => {
                this.addLineItem({
                    id: item.id,
                    // codes
                    tariff_code_txt: item.tariff_code_txt,
                    // tariff_code_id: item.tariffCode, // @TODO: tariffs
                    icd10_codes: item.icd10Codes,
                    nappi_code_id: item.nappiCode,
                    // meta
                    quantity: item.quantity,
                    unit_amount: item.unit_amount,
                });
            });
        }
    }

    private init(basketId: number) {
        this.basketLoaded = false;
        this.loadingStatus = 'Loading Basket Data';
        this.basketHttpService.find(basketId, {
            params: {
                include: [
                    'basketItems.nappiCode.nappiManufacturer',
                    'basketItems.icd10Codes.icd103Code.icd10Group.icd10Chapter'
                ].join(',')
            }
        }).pipe(
            take(1),
            tap(basket => this.patchForm(basket)),
            finalize(() => {
                this.basketLoaded = true;
                this.loadingStatus = null;
            })
        ).subscribe();
    }
}
