import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryModel } from '@epione/shared/models/main/country.model';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { CountriesHttpService } from '@epione/shared/services/main/countries.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'epione-address-picker',
	templateUrl: './address-picker.component.html',
	styleUrls: ['./address-picker.component.scss']
})
export class AddressPickerComponent implements OnInit, OnDestroy {
	@Input() public loadingStateName = 'loading';
	@Input() public streetAddressPlaceholder = 'Street address';
	@Input() public addressFormGroup!: FormGroup;
	@Input() public countryCode!: string;
	@ViewChild('search') public searchElementRef!: ElementRef;
	public countries: CountryModel[] = [];
	private displaySub?: Subscription;

	constructor(
		private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
		private countriesService: CountriesHttpService,
		private loadingStateService: LoadingStateService
	) {
	}

	ngOnInit(): void {
		this.initMapLoader();
		this.loadingStateService.start(this.loadingStateName);
		this.countriesService.getCountryList()
			.pipe(finalize(() => this.loadingStateService.end(this.loadingStateName)))
			.subscribe({ next: res => this.countries = res as CountryModel[] })
		this.displaySub = this.addressFormGroup.get('street')?.valueChanges.subscribe(v => {
			this.addressFormGroup.get('display')?.patchValue(v);
		});
	}

	ngOnDestroy() {
		if (this.displaySub) {
			this.displaySub.unsubscribe();
		}
	}

	private initMapLoader(): void {
		this.mapsAPILoader.load().then(() => {
			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
				types: ['address']
			});
			autocomplete.addListener("place_changed", () => {
				this.ngZone.run(() => {
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();

					if (place.geometry === undefined || place.geometry === null) {
						this.addressFormGroup.reset();
						return;
					}
					this.selectAddress(place);
				});
			});
		});
	}

	private resetFields(): void {
		this.addressFormGroup.reset();
	}

	private selectAddress(place: google.maps.places.PlaceResult): void {
		if (!place) {
			this.addressFormGroup.reset();
			return;
		}

		const city = place.address_components?.find(element => {
			return element.types.includes('locality') || element.types.includes('administrative_area_level_2');
		});
		const country = place.address_components?.find(element => {
			return element.types.includes('country');
		});
		const municipality = place.address_components?.find(element => {
			return element.types.includes('political') && element.types.includes('administrative_area_level_2');
		});
		const province = place.address_components?.find(element => {
			return element.types.includes('political') && element.types.includes('administrative_area_level_1');
		});
		const postalCode = place.address_components?.find(element => {
			return element.types.includes('postal_code');
		});

		const countryRecord = this.countries.find(({ name, code }) => {
			return code.toLowerCase() === (country?.short_name || '').toLowerCase();
		});

		this.addressFormGroup.patchValue({
			city: city ? city.long_name : '',
			municipality: municipality ? municipality.long_name : '',
			province: province ? province.long_name : '',
			post_code: postalCode ? postalCode.long_name : '',
			street: place.name,
			country_id: countryRecord?.id,
			lat: place.geometry?.location.lat(),
			lng: place.geometry?.location.lng()
		});
		// re-fit the display after hooks on street
		setTimeout(() => this.addressFormGroup.get('display')?.patchValue(place.formatted_address), 0);
	}
}
