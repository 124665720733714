<div class="container-fluid">
    <div class="row mb-4">
        <div class="col">
            <h1 class="text-epione text-uppercase font-weight-bold">Medical Aid Practice Settings</h1>
            <p>
                Assign a claim email address to medical aid by selecting the relevant medical aid below and capturing
                the email address. <b>NOTE:</b> The email will be sent from the system and will therefore COPY the
                claims email on the invoice being sent.
            </p>
        </div>
    </div>
    <div class="row mb-2" [formGroup]="settingsFormGroup">
        <div class="col-md-6">
            <div class="form-group">
                <ng-select placeholder="Start typing..."
                           [items]="medicalAidSchemes"
                           bindValue="id"
                           formControlName="medical_aid_scheme_id"
                           epioneLoadingState="medical-aid-practice-save"
                           bindLabel="name"
                           epioneFormRequired></ng-select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <input type="email"
                       class="form-control"
                       formControlName="email"
                       epioneFormRequired
                       placeholder="Enter email address"
                       epioneLoadingState="medical-aid-practice-save">
            </div>
        </div>
        <div class="col">
            <button class="btn text-light btn-primary bg-epione px-3"
                    (click)="addSettings()"
                    epioneLoadingState="medical-aid-practice-save">Add
            </button>
        </div>
    </div>
    <div class="row mb-1">
        <div class="col-12">
            <div class="float-right">
                <input [formControl]="$searchControl"
                       type="search"
                       class="form-control"
                       placeholder="Search..."
                       epioneLoadingState="medical-aid-practice-save">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-hover mt-5">
                <thead>
                    <tr class="text-epione text-uppercase">
                        <th scope="col" epioneSortable="medicalAidScheme.name" (sort)="sort($event)">Medical Aid Scheme
                            Name</th>
                        <th scope="col" epioneSortable="updated_at" (sort)="sort($event)">Last Updated</th>
                        <th scope="col" epioneSortable="email" (sort)="sort($event)">Assigned Claims Email Address</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="billingMedicalAids.length; else billingMedicalAidsLoading">
                        <tr *ngFor="let medicalAid of billingMedicalAids">
                            <td>{{medicalAid.medicalAidScheme?.name}}</td>
                            <td>{{medicalAid.updated_at | date: 'yyyy-MM-dd'}}</td>
                            <td>
                                <input type="text"
                                       class="form-control"
                                       epioneLoadingState="medical-aid-practice-save"
                                       value="{{medicalAid.email}}"
                                       [formControl]="medicalAid.emailControl">
                            </td>
                            <td>
                                <button
                                        class="btn text-light btn-primary bg-epione px-3"
                                        (click)="updateSettings(medicalAid)"
                                        epioneLoadingState="medical-aid-practice-save">Save Changes
                                </button>
                                <button
                                        class="btn btn-outline-primary ml-2 px-3"
                                        (click)="removeSettings(medicalAid.id)"
                                        epioneLoadingState="medical-aid-practice-save">Remove
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-template #billingMedicalAidsLoading>
                        <tr>
                            <td colspan="9">
                                <p class="text-center">{{loading ? 'Loading Settings...' : 'No Settings To Display'}}
                                </p>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
        <div class="col-12">
            <div class="float-right">
                <epione-pagination [pagination]="pagination" (change)="reload($event)"></epione-pagination>
            </div>
        </div>
    </div>


</div>