import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ErrorDialogService } from "@epione/shared/dialogs/error-dialog.service";
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpOptions } from "./http-api.service";

@Injectable({
    providedIn: 'root'
})
export abstract class CallsAPI<T, L> {
    protected abstract apiBasePath: string;

    /**
   * base url of this service
   * @protected
   * @type {string}
   * @memberof HttpApiService
   */
    protected abstract resourcePath: string;

    /**
     * Default Options per Request
     * @protected
     * @type {HttpOptions}
     * @memberof HttpApiService
     */
    protected defaultOptions?: HttpOptions;

    /**
     * @type {boolean}
     * @memberof CallsAPI
     */
    public shouldHandleValidation: boolean = false;

    /**
     * Default Options per Request
     * @protected
     * @type {HttpOptions}
     * @memberof HttpApiService
     */
    protected cachedResponses: { [key: string]: any } = {};

    /**
     * Default Options per Request
     * @protected
     * @type {HttpOptions}
     * @memberof HttpApiService
     */
    protected cachedRequests: { [key: string]: Observable<any> } = {};

    constructor(protected errorDialogService: ErrorDialogService) { }

    public withValidation(enabled: boolean = true) {
        this.shouldHandleValidation = enabled;
        return this;
    }

    /**
     * Build action url from path template and parameters
     * @param {string} path
     * @returns
     * @memberof HttpApiService
     */
    protected buildAction(path: string, params?: { [key: string]: string | number }, fromBaseUri: boolean = false) {
        params = params ? params : {};
        return Object.keys(params).reduce((url, paramKey) => {
            return url.replace(new RegExp(`\{\:${paramKey}\}`, 'ig'), `${params![paramKey]}`);
            // tslint:disable-next-line: max-line-length
        }, [
            this.apiBasePath.replace(/^\/|\/$/g, ''),
            ...(fromBaseUri ? [] : [
                this.resourcePath.replace(/^\/|\/$/g, '')
            ]),
            path.replace(/^\/|\/$/g, '')
        ].join('/').replace(/\/$/g, ''));
    }

    /**
     * build param options from defaults and overrides
     * @protected
     * @param {HttpOptions} [options]
     * @returns {HttpOptions}
     * @memberof HttpApiService
     */
    protected buildOptions(options?: HttpOptions): HttpOptions {
        // nothing set, use defaults (even if undefined)
        if (!options) {
            return this.defaultOptions ?? {};
        }

        // options set but no defaults, use what was set
        if (!this.defaultOptions) {
            return options;
        }

        return Object.assign({}, this.defaultOptions, options, {
            headers: Object.assign(
                {},
                this.defaultOptions.headers ? this.defaultOptions.headers : {},
                options.headers ? options.headers : {}
            ),
            params: Object.assign(
                {},
                this.defaultOptions.params ? this.defaultOptions.params : {},
                options.params ? options.params : {}
            )
        }) as HttpOptions;
    }

    /**
     * @protected
     * @param {Response} res
     * @returns
     * @memberof HttpApiService
     */
    protected handleValidationErrors(res: HttpErrorResponse): HttpErrorResponse {
        // only handle valdiation errors here
        if (res.status === 422 && this.shouldHandleValidation) {
            if (res.error && res.error.errors) {
                this.errorDialogService.showErrorDialogFromResponse(res);
            }
        }

        return res;
    }

    /**
     * @protected
     * @param {*} toBeDetermined
     * @returns {toBeDetermined is PaginatedResponse<T>}
     * @memberof HttpApiNestedService
     */
    protected isPaginatedResponse<T>(toBeDetermined: any): toBeDetermined is PaginatedResponse<T> {
        if ((toBeDetermined as PaginatedResponse<T>).meta) {
            return true;
        }
        return false;
    }

    /**
     * @private
     * @param {*} res
     * @returns
     * @memberof HttpApiService
     */
    private sanitizeMessage(res: any) {
        let messages: string[] = [];
        messages.push(res.error.message);

        if (res.error.errors) {
            for (let value in res.error.errors) {
                if (Array.isArray(res.error.errors[value])) {
                    res.error.errors[value].forEach((item: string) => messages.push(item));
                }
            }
        }

        return messages;
    }

    /**
     * Get and/or Set cached response data by hash key
     * @protected
     * @param {string} key
     * @param {(() => Observable<T | L>)} [func]
     * @returns {(Observable<T | L>)}
     * @memberof HttpApiService
     */
    protected cachedResponse<R = any>(key: string, func: () => Observable<R>, refresh: boolean = false): Observable<R> {
        if (!refresh && this.cachedResponses[key]) {
            return of(this.cachedResponses[key]);
        }
        if (this.cachedRequests[key]) {
            return this.cachedRequests[key];
        }
        const request = func().pipe(
            tap(data => this.cachedResponses[key] = data),
            tap(() => delete this.cachedRequests[key]) // clear self reference
        );
        // keep reference for debouncing
        this.cachedRequests[key] = request;
        return request;
    }
}