import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoiceModel } from '@epione/shared/models/invoice.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { MedicalAidPracticeService } from "@epione/modules/settings/medical-aid-practice/services/http/medical-aid-practice.service";
import { finalize, take, tap } from "rxjs/operators";
import { FormControl } from "@angular/forms";

type Action = 'cancel' | 'send' | 'print';
export type InvoiceAction = { action: Action, sendClaimEmail: boolean };

@Component({
    selector: 'epione-send-invoice-modal',
    templateUrl: './send-invoice-modal.component.html',
    styleUrls: ['./send-invoice-modal.component.scss']
})
export class SendInvoiceModalComponent implements OnInit, OnDestroy {

    public invoiceAction$: Subject<InvoiceAction> = new Subject<InvoiceAction>();
    public invoice?: InvoiceModel;
    public claimEmail: string = '';
    public showClaimsSection: boolean = false;
    public sendClaimEmail: boolean = false;
    public subscription?: Subscription;
    public loaded: boolean = false;

    constructor(
        public modal: NgbActiveModal,
        private medicalAidPracticeService: MedicalAidPracticeService
    ) {
    }

    ngOnInit(): void {
        const schemeId = this.invoice?.account?.medicalAidOption?.medicalAidPlan?.medicalAidScheme?.id;
        if (schemeId) {
            this.medicalAidPracticeService.listAll({
                params: {
                    'filter[medical_aid_scheme_id]': `${schemeId}`,
                }
            }).pipe(
                take(1),
                tap(res => {
                    if (res.length > 0) {
                        this.showClaimsSection = true;
                        this.claimEmail = res[0].email;
                    }
                }),
                finalize(() => this.loaded = true)
            ).subscribe();
        } else {
            this.loaded = true;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
