<div class="container-fluid">
    <div class="row mb-4">
        <div class="col">
            <h1 class="text-epione text-uppercase font-weight-bold">Billing Account Mapping</h1>
            <p>Map each epione.net account below to the corresponding account in your billing provider system.</p>
        </div>
    </div>
    <ng-container *ngIf="!loadingStatus; else loading">
        <form [formGroup]="mappingForm">
            <div class="row">
                <div class="col-12 col-xl-8">
                    <div class="row mb-3">
                        <div class="col-6">
                            <div class="h5 text-epione text-uppercase font-weight-bold">epione.net Accounts</div>
                        </div>
                        <div class="col-6">
                            <div class="h5 text-epione text-uppercase font-weight-bold">Billing Provider Accounts</div>
                        </div>
                    </div>
                    <div class="form-group card"
                         *ngFor="let account of finAccounts">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <label for="mapping-{{account.id}}"><strong>{{ account.name }}</strong></label>
                                    <p class="text-muted">{{ account.description }}</p>
                                </div>
                                <div class="col-6">
                                    <ng-select labelForId="mapping-{{account.id}}"
                                               formControlName="{{account.id}}"
                                               [items]="account.payable ? providerAccountsPayable : providerAccounts"
                                               (change)="providerAccountChanged($event, account.id)"
                                               placeholder="Select an account..."
                                               [searchFn]="searchAccount"
                                               epioneFormRequired>
                                        <ng-template ng-label-tmp
                                                     let-item="item">
                                            <span>{{item.provider_name}}{{item.provider_code ? ' - (Account Code: ' +
                                                item.provider_code
                                                +
                                                ')': ''}}</span>
                                        </ng-template>
                                        <ng-template ng-option-tmp
                                                     let-item="item">
                                            <span> {{item.provider_name}}{{item.provider_code ? ' - (Account Code: ' +
                                                item.provider_code +
                                                ')' : ''}}</span>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="epione-line mt-3 mb-3">
                <div class="d-flex justify-content-end">
                    <div class="btn btn-primary bg-epione"
                         (click)="submitMappings()">Submit Mappings</div>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-template #loading>
        <div>
            <loader [message]="loadingStatus"></loader>
        </div>
    </ng-template>
</div>