import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { FinAccountModel } from '@epione/shared/models/fin-account.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinAccountHttpService extends BillingHttpApiService<FinAccountModel, FinAccountModel[]> {
  protected resourcePath = "settings/fin-accounts";

  constructor(
    protected http: HttpClient,
    protected errorDialogService: ErrorDialogService
  ) {
    super(http, errorDialogService);
  }

  public list(): Observable<FinAccountModel[]> {
    return this.cachedResponse<FinAccountModel[]>('fin-accounts', () => super.list());
  }
}
