import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AccountsHttpService } from "@epione/modules/account/services/accounts-http.service";
import { SortableDirective, SortEvent } from '@epione/shared/directives/sortable.directive';
import { AccountModel } from '@epione/shared/models/account.model';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbstractControl, FormArray } from '@angular/forms';
@Component({
  selector: 'epione-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit, OnDestroy {

  @ViewChildren(SortableDirective) sortableColumns!: QueryList<SortableDirective>;
  public loading: boolean = true;
  public accounts?: AccountModel[];
  public pagination?: Pagination;
  public filters!: { [key: string]: string | null | Date | Array<string | null | Date> };
  public search: string = '';
  public order: SortEvent = { column: 'updated_at', direction: 'desc' };
  private options: any = {
    page: 1,
    per_page: 15,
    include: 'medicalAidOption.medicalAidPlan.medicalAidScheme,accountStanding,guarantorAccountStanding',
  };
  private reload$: Subject<void> = new Subject<void>();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private accountsHttpService: AccountsHttpService
  ) {
    this.resetFilters();
  }

  ngOnInit(): void {
    this.reload$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.loadData())
    ).subscribe();
    this.reload();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  set filterStartDate(date: null | Date) {
    (this.filters.created_at! as Array<null | Date>)[0] = date;
  }
  set filterEndDate(date: null | Date) {
    (this.filters.created_at! as Array<null | Date>)[1] = date;
  }

  get filterStartDate(): null | Date {
    return (this.filters.created_at! as Array<null | Date>)[0];
  }
  get filterEndDate(): null | Date {
    return (this.filters.created_at! as Array<null | Date>)[1];
  }

  public resetFilters() {
    this.filters = {
      account_number: null,
      passport_number: null,
      patient_name: null,
      medical_aid_number: null,
      invoice_number: null,
      created_at: [null, null]
    };
  }

  public setFilterDate(date: Date, idx: number) {
    let filter = '';
    if (date) {
      const dateObj = moment(date as Date);
      if (dateObj.isValid()) {
        filter = dateObj.startOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
    }
    (this.filters.created_at as string[])[idx] = filter;
  }

  public sort(event: SortEvent) {
    // reset other columns
    this.sortableColumns.filter(s => !s.is(event)).forEach(s => s.reset())
    this.order = event;
    this.reload();
  }

  public reload(page?: number) {
    if (page) {
      this.options.page = page;
    }
    this.loading = true;
    // clear state
    this.accounts = undefined;
    this.pagination = undefined;
    this.reload$.next();
  }

  private loadData() {
    return this.accountsHttpService.list({
      params: {
        ...this.options,
        ...(this.search !== '' ? { search: this.search } : {}),
        ...{ order: this.order.column + ',' + this.order.direction },
        ...Object.keys(this.filters).reduce<{ [key: string]: string }>((out, key) => {
          if (Array.isArray(this.filters[key])) {
            // must have all elements
            if ((this.filters[key] as any[]).filter(Boolean).length === (this.filters[key] as any[]).length) {
              out[`filter[${key}]`] = (this.filters[key] as Array<string | Date>).map(v => {
                return v instanceof Date ? v.toISOString() : v;
              }).join(',');
            }
          } else if (!!this.filters[key] && this.filters[key] !== '') {
            out[`filter[${key}]`] = this.filters[key] as string;
          }
          return out;
        }, {})
      }
    }).pipe(
      finalize(() => this.loading = false),
      take(1),
      tap((res) => {
        this.accounts = res.data;
        this.pagination = res.meta.pagination;
      })
    );
  }

}
