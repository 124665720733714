export enum InvoiceStatusAlias {
    DRAFT = 'draft',
    SUBMITTED = 'submitted',
    AUTHORISED = 'authorised',
    PAID = 'paid',
    VOIDED = 'voided',
}

export enum InvoiceStatusId {
    DRAFT = 1,
    SUBMITTED = 2,
    AUTHORISED = 3,
    PAID = 4,
    VOIDED = 5,
}