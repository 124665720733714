import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination } from '@epione/shared/types/paginatedResponse';

@Component({
  selector: 'epione-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input('pagination') pagination?: Pagination;
  @Input('rotate') rotate: boolean = true;
  @Input('maxSize') maxSize: number = 10;
  @Input('boundaryLinks') boundaryLinks: boolean = true;
  @Output('change') change: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  get page() {
    return this.pagination?.current_page || 1;
  }

  set page(page: number) {
    this.change.emit(page);
  }

}
