<div class="form-group m-0">
    <div class="form-control pseudo-control" [attr.disabled]="disabled"
         (click)="openModal(icdSelectModal)">
        <ng-container *ngIf="codeValues && codeValues.length; else noValue">
            <div *ngFor="let code of codeValues">
                <strong>[&nbsp;{{code?.code}}&nbsp;]</strong>&nbsp;{{code?.description}}
            </div>
        </ng-container>
        <ng-template #noValue>
            <span class="text-muted">Select ICD10 Code(s)...</span>
        </ng-template>
    </div>
</div>
<ng-template #icdSelectModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select ICD10 Code(s)</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <ng-select placeholder="Search For ICD10 Code ..."
                       [items]="codes$ | async"
                       id="code"
                       [(ngModel)]="codeValues"
                       [loading]="codesLoading"
                       [disabled]="!!disabled"
                       [typeahead]="codeSearchTerm$"
                       [multiple]="true"
                       epioneLoadingState="invoice-save">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label">
                        <strong>[&nbsp;{{item?.code}}&nbsp;]</strong>&nbsp;{{item?.description}}
                    </span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <strong>[&nbsp;{{item?.code}}&nbsp;]</strong>&nbsp;{{item?.description}}
                    <div class="text-muted">
                        <strong>&#x221F;[&nbsp;{{item?.icd103Code?.code}}&nbsp;]</strong>&nbsp;{{item?.icd103Code?.description}}
                    </div>
                    <div class="text-muted">
                        <strong>&nbsp;&#x221F;[&nbsp;{{item?.icd103Code?.icd10Group?.code}}&nbsp;]</strong>&nbsp;{{item?.icd103Code?.icd10Group?.description}}
                    </div>
                    <div class="text-muted">
                        <strong>&nbsp;&nbsp;&#x221F;[&nbsp;{{item?.icd103Code?.icd10Group?.icd10Chapter?.chapter_number}}&nbsp;]</strong>&nbsp;{{item?.icd103Code?.icd10Group?.icd10Chapter?.description}}
                    </div>
                </ng-template>
                <ng-template ng-footer-tmp>
                    <span class="text-muted">
                        {{codePagination ? (codePagination.total - codePagination.count) : 0}} More
                        Records
                    </span>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('done')">Done</button>
    </div>
</ng-template>