import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { AccountModel } from '@epione/shared/models/account.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsHttpService extends BillingHttpApiService<AccountModel> {
  protected resourcePath = 'accounts'

  constructor(protected http: HttpClient, protected errorDialogService: ErrorDialogService) {
    super(http, errorDialogService);
  }
}
