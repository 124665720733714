import { Injectable } from '@angular/core';
import { GPModel } from '@epione/shared/models/main/gp.model';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { MainHttpApiService } from '../http/main-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class GPHttpService extends MainHttpApiService<GPModel, PaginatedResponse<GPModel>> {
    protected resourcePath: string = 'gps';
}
