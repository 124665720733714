import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenRefreshInterceptor } from './token-refresh-interceptor';
import { AuthInterceptor } from './auth-interceptor';
import { BaseInterceptor } from './base-interceptor';
import { SanitizeInterceptor } from './sanitize-interceptor';

/**
 * Registered Interceptors to be provided to the application
 *
 * @var []
 */
export const httpInterceptorProviders = [
    // TokenRefreshInterceptor should be first as it requires subsequent interceptors to fire within its own requests
    { provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true },

    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    // SanitizeInterceptor should always be last to clear out any bypass headers sent
    { provide: HTTP_INTERCEPTORS, useClass: SanitizeInterceptor, multi: true }
];
