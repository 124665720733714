import { Injectable } from '@angular/core';
import { MedicalAidOptionModel } from '@epione/shared/models/main/medical-aid-option.model';
import { MainHttpApiService } from "@epione/shared/services/http/main-http-api.service";

@Injectable({
  providedIn: 'root'
})
export class MedicalAidOptionsService extends MainHttpApiService<MedicalAidOptionModel, MedicalAidOptionModel[]> {
  protected resourcePath = 'medical-aid/option';
}
