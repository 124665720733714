import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrencyModel } from '@epione/shared/models/currency.model';
import Decimal from 'decimal.js';

@Component({
  selector: 'epione-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CurrencyInputComponent), multi: true }
  ]
})
export class CurrencyInputComponent implements OnInit, ControlValueAccessor {

  @Input() currency!: CurrencyModel;

  @Input() disabled: boolean = false;
  public input: string | null = null;
  private _value: Decimal | null = null;
  constructor() { }

  ngOnInit(): void {
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  get value(): number | null {
    return this._value ? this._value.toNumber() : null;
  }

  set value(val: number | null) {  // this value is updated by programmatic changes
    if (val !== undefined && this._value !== val) {
      this._value = val ? new Decimal(val) : null;
      this.onChange(val)
      this.onTouch(val)
    }
    this.input = this._value ? (this._value.div(new Decimal(100))).toFixed(2) : null;
  }

  setValue() {
    if (this.input) {
      const val = new Decimal(this.input);
      if (!val.isNaN()) {
        this.value = val.times(100).toNumber(); // cents
        return;
      }
    }
    this.value = null;
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
