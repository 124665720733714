import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize, take, tap } from 'rxjs/operators';
import { BasketModel } from '@epione/shared/models/basket.model';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { BasketHttpService } from '@epione/modules/settings/basket/services/basket-http.service';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'epione-select-basket',
    templateUrl: './select-basket.component.html',
    styleUrls: ['./select-basket.component.scss']
})
export class SelectBasketComponent implements OnInit, OnDestroy {

    public baskets!: BasketModel[];
    public subject?: Subject<any>;
    public search: string = '';
    public pagination?: Pagination;
    public basketsLoading: boolean = false;
    public basketSearchTerm$: Subject<string> = new Subject<string>();
    public disabled?: boolean = false;
    public selectedBasket!: BasketModel;
    public selectOpen: boolean = false;
    public closure: Function = () => {};

    private options: any = {
        page: 1,
        per_page: 10,
        include: 'basketItems.nappiCode,basketItems.icd10Codes'
    };
    private $subscription!: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private basketHttpService: BasketHttpService,
        private loadingStateService: LoadingStateService,
    ) {
    }

    ngOnInit(): void {
        this.$subscription = this.basketSearchTerm$.pipe(
            distinctUntilChanged(),
            tap((term) => this.search = term)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.$subscription.unsubscribe();
    }

    public searchBaskets() {
        this.selectOpen = true;
        this.loadingStateService.start('basket-select');
        this.basketsLoading = true;
        this.loadData().pipe(tap(() => {
            this.loadingStateService.end('basket-select');
            this.basketsLoading = false;
        })).subscribe();
    }

    public addBasket() {
        if (!this.selectedBasket) {
            return;
        }
        this.closure(this.selectedBasket);
    }

    private loadData() {
        return this.basketHttpService.list({
            params: {
                ...this.options,
                ...(this.search !== '' ? { search: this.search } : {})
            }
        }).pipe(
            take(1),
            tap((res) => {
                this.baskets = res.data;
                this.pagination = res.meta.pagination;
            })
        );
    }
}
