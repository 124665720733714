<div class="input-group">
    <div class="input-group-prepend">
        <div class="input-group-text">{{currency.alphabetic_code}}</div>
    </div>
    <input type="text" class="form-control currency-input"
           epioneLoadingState="invoice-save"
           placeholder="0.00"
           [disabled]="disabled"
           (blur)="setValue()"
           [(ngModel)]="input">
</div>