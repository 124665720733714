import { Injectable } from '@angular/core';
import { MemberPreferencesModel } from '@epione/shared/models/member-preferences.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class MemberPreferencesHttpService extends BillingHttpApiService<MemberPreferencesModel, MemberPreferencesModel[]> {
  protected resourcePath = "settings/member-preferences";
}
