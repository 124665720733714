<div class="container-fluid" *ngIf="!loadingStatus; else loading">
    <div class="row">
        <div class="col">
            <h1 class="text-epione text-uppercase font-weight-bold">{{ title }}</h1>
        </div>
        <div *ngIf="action === 'update'" class="col-auto">
            <p>Created At: <span class="float-right ml-1">{{ account?.created_at | date: 'yyyy-MM-dd' }}</span> </p>
            <p>Last Updated: <span class="float-right ml-1">{{ account?.updated_at | date: 'yyyy-MM-dd' }}</span></p>
        </div>
    </div>
    <div class="row" *ngIf="action === 'create'">
        <div class="col line-top">
            <div class="col">
                <h3 class="text-epione text-uppercase pt-4 font-weight-bolder">
                    Select Patient Record
                </h3>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="patient" class="text-epione">
                                First, please select the patient record for which you intend to create a billing account
                            </label>
                            <ng-select placeholder="Search For Patient Record..."
                                       [items]="patients$ | async"
                                       id="patient"
                                       [(ngModel)]="patient"
                                       (change)="preloadPatientData($event)"
                                       [loading]="patientsLoading"
                                       [typeahead]="patientSearchTerm$"
                                       epioneLoadingState="account-save">
                                <ng-template ng-label-tmp let-item="item">
                                    {{item.label}}&nbsp;
                                    <span class="text-muted">[&nbsp;{{item.meta}}&nbsp;]</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    {{item.label}}<br>
                                    <span class="text-muted">[&nbsp;{{item.meta}}&nbsp;]</span>
                                </ng-template>
                                <ng-template ng-footer-tmp>
                                    <span class="text-muted">
                                        {{pagination ? (pagination.total - pagination.count) : 0}} More Records
                                    </span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="action === 'update' || (action === 'create' && patient)" [formGroup]="accountFormGroup">
        <div class="row">
            <div class="col line-top">
                <div class="epione-line"></div>
                <div class="col">
                    <h3 class="text-epione text-uppercase pt-4 font-weight-bolder">
                        Primary Payment Method
                    </h3>
                    <ng-container *ngIf="action === 'update'">
                        <div class="row mb-3">
                            <div class="col">
                                <label class="text-epione">
                                    Primary Account (This is to associate this account to another account i.e. the
                                    guarantor's practice account. This action requires the epione.net Account Number of the
                                    guarantor.)
                                </label><br>
                                <button *ngIf="!account?.guarantor" class="btn btn-outline-primary" (click)="associateAccount()">
                                    Associate Account
                                </button>
                                <div *ngIf="account?.guarantor">
                                    <span>{{ getGuarantorName() }}</span>&nbsp;&nbsp;
                                    <button class="btn btn-outline-primary" (click)="removeAssociation()">
                                        Remove Association
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="payment-method" class="text-epione">
                                    Primary Payment Method * (This is the primary method used by the patient for doctor
                                    visits)
                                </label>
                                <select id="payment-method" class="form-control" formControlName="payment_method_id"
                                        epioneLoadingState="account-save">
                                    <option value="" selected disabled>Select payment method</option>
                                    <option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.key">
                                        {{ paymentMethod.value }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" *ngIf="showMedicalAids">
                                <label for="medical-aid" class="text-epione">
                                    Medical Aid
                                </label>
                                <ng-select placeholder="Select medical aid" id="medical-aid" [items]="medicalAidSchemes"
                                           bindValue="id" formControlName="medical_aid_scheme_id"
                                           epioneLoadingState="account-save"
                                           bindLabel="name"
                                           epioneFormRequired></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="showMedicalAids">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="plan" class="text-epione">
                                    Plan
                                </label>
                                <ng-select placeholder="Select a plan"
                                           [items]="medicalAidPlans"
                                           [readonly]="disabled('medical_aid_scheme_id')"
                                           id="plan"
                                           bindValue="id"
                                           formControlName="medical_aid_plan_id"
                                           epioneLoadingState="account-save"
                                           bindLabel="name"
                                           epioneFormRequired>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="option" class="text-epione">
                                    Option
                                </label>
                                <ng-select placeholder="Select option"
                                           [items]="medicalAidOptions"
                                           id="option"
                                           bindValue="id"
                                           [readonly]="disabled('medical_aid_plan_id')"
                                           epioneLoadingState="account-save"
                                           bindLabel="name"
                                           formControlName="medical_aid_option_id"
                                           epioneFormRequired></ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="showMedicalAids">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="main-member-id" class="text-epione">
                                    Main Member ID
                                </label>
                                <input type="text" class="form-control" id="main-member-id"
                                       formControlName="main_member_id"
                                       epioneLoadingState="account-save"
                                       epioneFormRequired>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="medical_aid_number" class="text-epione">
                                    Membership Number
                                </label>
                                <input type="text" class="form-control" id="medical_aid_number"
                                       formControlName="medical_aid_number"
                                       epioneLoadingState="account-save"
                                       epioneFormRequired>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="showMedicalAids">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="effective-date" class="text-epione">
                                    Effective Date (Active On)
                                </label>
                                <div class="input-group mb-2 mr-sm-2">
                                    <input type="text" class="form-control"
                                           ngbDatepicker
                                           epioneLoadingState="account-save"
                                           #effectiveDate="ngbDatepicker"
                                           formControlName="effective_date"
                                           epioneFormRequired
                                           [labelDepth]="2"
                                           id="effective-date">
                                    <div class="input-group-prepend" (click)="effectiveDate.toggle()">
                                        <div class="input-group-text">
                                            <span class="material-icons">calendar_today</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="dependant-code" class="text-epione">
                                    Dependant Code
                                </label>
                                <input type="text"
                                       class="form-control"
                                       id="dependant-code"
                                       formControlName="dependant_code"
                                       epioneFormRequired
                                       epioneLoadingState="account-save">
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="showMedicalAids">
                        <div class="col-md-6">
                            <input type="checkbox" id="gap-cover" class="mt-2 mb-2 mr-3"
                                   epioneLoadingState="account-save"
                                   epioneFormRequired
                                   formControlName="gap_cover">
                            <label for="gap-cover" class="text-epione">GAP Cover</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col line-top">
                <div class="epione-line"></div>
                <div class="col" formGroupName="contact_details">
                    <h3 class="text-epione text-uppercase pt-4 font-weight-bolder">
                        Person Responsible For Account Contact Details
                    </h3>

                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="mobile-number" class="text-epione">
                                    Mobile Number
                                </label>
                                <input type="text"
                                       class="form-control"
                                       id="mobile-number"
                                       formControlName="mobile_number"
                                       epioneFormRequired
                                       placeholder="+27838884477"
                                       epioneLoadingState="account-save">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email" class="text-epione">
                                    Email
                                </label>
                                <input type="email"
                                       class="form-control"
                                       id="email"
                                       formControlName="email"
                                       epioneFormRequired
                                       placeholder="user@example.com"
                                       epioneLoadingState="account-save">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="telephone-number" class="text-epione">
                                    Telephone Number
                                </label>
                                <input type="text"
                                       class="form-control"
                                       id="telephone-number"
                                       formControlName="telephone_number"
                                       epioneFormRequired
                                       placeholder="+27118884477"
                                       epioneLoadingState="account-save">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="fax-number" class="text-epione">
                                    Fax Number
                                </label>
                                <input type="text"
                                       class="form-control"
                                       id="fax-number"
                                       formControlName="fax_number"
                                       epioneFormRequired
                                       placeholder="+27118884477"
                                       epioneLoadingState="account-save">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col line-top line-bottom">
                <div class="epione-line"></div>
                <div class="col" formGroupName="address">
                    <h3 class="text-epione text-uppercase pt-4 font-weight-bolder">
                        Billing Address
                    </h3>

                    <epione-address-picker loadingStateName="account-save"
                                           [addressFormGroup]="addressFormGroup"></epione-address-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button class="btn text-light btn-primary bg-epione px-3" (click)="saveAccount()"
                        epioneLoadingState="account-save">
                    {{ action === 'create' ? 'Create Account' : 'Save Account Information' }}
                </button>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #loading>
    <div class="">
        <loader [message]="loadingStatus"></loader>
    </div>
</ng-template>
