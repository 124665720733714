import { Injectable } from '@angular/core';
import { NappiCodeModel } from '@epione/shared/models/codes/nappi.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';

@Injectable({
  providedIn: 'root'
})
export class NappiHttpService extends BillingHttpApiService<NappiCodeModel, PaginatedResponse<NappiCodeModel>> {
  protected resourcePath = "codes/nappi";
}
