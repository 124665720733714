import { environment } from '../../../environments/environment';
export const APP_CONFIG = {
	API_URL: {
		billing: environment.api.billing,
		main: environment.api.main,
	},
	APP_URL: {
		main: environment.app.main
	},
	STORAGE_KEY: 'Epione',
	APP_VERSION: environment.app_version,
};