<div [formGroup]="addressFormGroup">
    <div class="row mb-2">
        <div class="col-md-6">
            <div class="form-group">
                <label class="text-epione">Unit Number</label>
                <input [epioneLoadingState]="loadingStateName"
                       type="text"
                       placeholder="Unit Number"
                       epioneFormRequired
                       formControlName="unit_number"
                       class="form-control" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="text-epione">Complex/Estate</label>
                <input [epioneLoadingState]="loadingStateName"
                       type="text"
                       placeholder="Complex/Estate"
                       formControlName="complex"
                       epioneFormRequired
                       class="form-control" />
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-md-6">
            <label class="text-epione">Street address</label>
            <input #search
                   [epioneLoadingState]="loadingStateName"
                   (keydown.enter)="$event.preventDefault()"
                   autocorrect="off"
                   autocapitalize="off"
                   spellcheck="off"
                   epioneFormRequired
                   formControlName="street"
                   type="text"
                   [placeholder]="streetAddressPlaceholder"
                   class="form-control" />
        </div>
        <div class="col-md-6">
            <label class="text-epione">Postal Code</label>
            <input [epioneLoadingState]="loadingStateName"
                   type="text"
                   epioneFormRequired
                   formControlName="post_code"
                   placeholder="Postal code"
                   class="form-control" />
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-md-6">
            <label class="text-epione">City</label>
            <input [epioneLoadingState]="loadingStateName"
                   type="text"
                   epioneFormRequired
                   formControlName="city"
                   placeholder="City"
                   class="form-control" />
        </div>
        <div class="col-md-6">
            <label class="text-epione">Municipality/District</label>
            <input [epioneLoadingState]="loadingStateName"
                   type="text"
                   epioneFormRequired
                   formControlName="municipality"
                   placeholder="Municipality/District"
                   class="form-control" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label class="text-epione">Province</label>
                <input [epioneLoadingState]="loadingStateName"
                       epioneFormRequired
                       formControlName="province"
                       placeholder="Province"
                       class="form-control" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="country" class="text-epione">
                    Country
                </label>
                <ng-select placeholder="Select country"
                           [items]="countries"
                           id="country"
                           epioneFormRequired
                           formControlName="country_id"
                           bindValue="id"
                           [epioneLoadingState]="loadingStateName"
                           bindLabel="name"></ng-select>
            </div>
        </div>
    </div>
</div>