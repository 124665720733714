import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@epione/shared/config/common.config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinAccountMappingService {

  constructor(
    private http: HttpClient
  ) { }

  public checkAccountMappings(): Observable<boolean> {
    return this.http.get(`${APP_CONFIG.API_URL.billing}/settings/validate-mappings`, { observe: 'response' }).pipe(
      map(res => {
        // 204 - No Content === Valid
        // Errors will be 4XX or 5XX 
        return res.status === 204;
      })
    )
  }
}
