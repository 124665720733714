import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CountryModel } from '@epione/shared/models/main/country.model';
import { MainHttpApiService } from '@epione/shared/services/http/main-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesHttpService extends MainHttpApiService<CountryModel, CountryModel[]> {
  protected resourcePath: string = 'patients/countries';
  private countries: CountryModel[] = [];
  private activeRequest?: Subject<CountryModel[]>;

  public getCountryList(): Observable<CountryModel[]> {
    if (!this.countries.length) {
      if (!this.activeRequest) {
        return this.list().pipe(
          switchMap(res => {
            this.activeRequest = new Subject<CountryModel[]>();
            this.countries = res;
            this.activeRequest.next(res);
            this.activeRequest.complete();
            return of(this.countries);
          })
        );
      }
      return this.activeRequest.asObservable();
    }
    return of(this.countries);
  }
}
