<div class="form-group m-0">
    <label for="case" class="text-epione">
        Please select the case record for which you intend to create an invoice
    </label>
    <ng-select placeholder="Search For Case Record..."
               [items]="cases$ | async"
               id="case"
               [(ngModel)]="case"
               [compareWith]="compareFn"
               [disabled]="!!disabled"
               [loading]="casesLoading"
               [typeahead]="caseSearchTerm$"
               bindLabel="label"
               bindValue="value">
        <ng-template ng-label-tmp let-item="item">
            <ng-container *ngIf="item.label !== null">{{item.label}}</ng-container>
            <ng-container *ngIf="item.label === null">
                Case: {{item.value.case.code}}&nbsp;<span
                      [ngStyle]="{ 'color': item.value.case.status.alias === 'open' ? 'green' : 'red'}">({{item.value.case.status.name.toUpperCase()}})</span>
                <span class="text-muted">&nbsp;[&nbsp;Billable Stage: {{item.value.stage.name}}&nbsp;]</span>
            </ng-container>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <ng-container *ngIf="item.label !== null">{{item.label}}</ng-container>
            <ng-container *ngIf="item.label === null">
                Case: {{item.value.case.code}}&nbsp;<span
                      [ngStyle]="{ 'color': item.value.case.status.alias === 'open' ? 'green' : 'red'}">({{item.value.case.status.name.toUpperCase()}})</span><br>
                <span class="text-muted">[&nbsp;Billable Stage: {{item.value.stage.name}}&nbsp;]</span>
            </ng-container>
        </ng-template>
        <ng-template ng-footer-tmp>
            <span class="text-muted">
                {{casePagination ? (casePagination.total - casePagination.count) : 0}} More
                Records
            </span>
        </ng-template>
    </ng-select>
</div>