<div class="container-fluid">
    <div class="row mb-4">
        <div class="col">
            <h1 class="text-epione text-uppercase font-weight-bold">Practice Member Preferences</h1>
            <p>Setup integration preferences for each member of the practice.</p>
        </div>
    </div>
    <ng-container *ngIf="!loadingStatus; else loading">
        <div class="card mb-3" *ngFor="let settings of memberForms">
            <form [formGroup]="settings.form">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h3>{{ settings.member.name }}</h3>
                        </div>
                    </div>
                    <div class="row ml-2" *ngIf="visibleProviders.xero">
                        <div class="col-12">
                            <h4>Xero Preferences</h4>
                        </div>
                        <div class="col-6">
                            <div class="form-group row no-gutters">
                                <label class="col-auto col-form-label">Branding Theme To Apply For This Member:</label>
                                <div class="col px-3">
                                    <ng-select placeholder="Select a Branding Theme"
                                               [items]="xeroBrandingThemes"
                                               id="plan"
                                               bindValue="provider_guid"
                                               formControlName="xero_branding_theme_guid"
                                               bindLabel="provider_name"
                                               [epioneLoadingState]="'member-pref-'+settings.member.id"
                                               [loading]="!!settings.loading"
                                               epioneFormRequired>
                                    </ng-select>
                                </div>
                                <div class="col-auto">
                                    <div class="icon-container" (click)="loadBrandingThemes(true, settings)">
                                        <mat-icon
                                                  [ngClass]="{ 'icon-loading': settings.loading }">
                                            autorenew
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary"
                                    [epioneLoadingState]="'member-pref-'+settings.member.id"
                                    (click)="savePreferences(settings)">
                                Save Preferences
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-template #loading>
        <div>
            <loader [message]="loadingStatus"></loader>
        </div>
    </ng-template>
</div>