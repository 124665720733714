import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { USER_ROLE_ID } from '@epione/shared/config/roles.config';
import { GPModel } from '@epione/shared/models/main/gp.model';
import { PrimaryCareNurseModel } from '@epione/shared/models/main/primary-care-nurse.model';
import { SpecialistModel } from '@epione/shared/models/main/specialist.model';
import { HttpApiService } from '@epione/shared/services/abstract/http/http-api.service';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { GPHttpService } from '@epione/shared/services/main/gp.service';
import { PracticeMemberHttpService } from '@epione/shared/services/main/practice-member.service';
import { PrimaryCareNurseHttpService } from '@epione/shared/services/main/primary-care-nurse.service';
import { SpecialistHttpService } from '@epione/shared/services/main/specialist.service';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

interface PractitionerSearchResult {
  isActive: boolean;
  label: string;
  meta: string;
  id: number;
  role_id: number;
};

@Component({
  selector: 'epione-select-practitioner',
  templateUrl: './select-practitioner.component.html',
  styleUrls: ['./select-practitioner.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectPractitionerComponent), multi: true }
  ]
})
export class SelectPractitionerComponent implements OnInit, ControlValueAccessor {

  @Input() practitionerId?: number;
  @Input() practitionerRoleId?: number;
  @Output() loaded: EventEmitter<PractitionerSearchResult | null> = new EventEmitter<PractitionerSearchResult | null>();
  public practitionerSearchTerm$: Subject<string> = new Subject<string>();
  public practitionersLoading: boolean = false;
  public practitioners$!: Observable<PractitionerSearchResult[]>;
  public practitionerPagination!: Pagination;
  private _practitioner: PractitionerSearchResult | null = null;
  constructor(
    private activeUserService: ActiveUserService,
    private practiceMemberHttpService: PracticeMemberHttpService,
    private gpHttpService: GPHttpService,
    private specialistHttpService: SpecialistHttpService,
    private nurseHttpService: PrimaryCareNurseHttpService
  ) { }

  ngOnInit(): void {
    this.practitioners$ = concat<PractitionerSearchResult[]>(
      // default items
      this.practitionerId ? this.practiceMemberHttpService.find(this.activeUserService.practiceId as number, this.practitionerId).pipe(
        catchError(err => {
          // member no longer exists in practice
          if (err instanceof HttpErrorResponse && err.status === 404) {
            return (
              {
                [USER_ROLE_ID.GENERAL_PRACTITIONER]: this.gpHttpService,
                [USER_ROLE_ID.SPECIALIST]: this.specialistHttpService,
                [USER_ROLE_ID.PRIMARY_CARE_NURSE]: this.nurseHttpService
              }[this.practitionerRoleId as number] as HttpApiService<GPModel | SpecialistModel | PrimaryCareNurseModel>
            ).find(this.practitionerId, { params: { include: 'profile' } }).pipe(
              map((d: GPModel | SpecialistModel | PrimaryCareNurseModel) => {
                let name = '', practitioner;
                switch (this.practitionerRoleId) {
                  case USER_ROLE_ID.GENERAL_PRACTITIONER:
                  case USER_ROLE_ID.SPECIALIST:
                    practitioner = d as GPModel | SpecialistModel;
                    name = [
                      practitioner.profile?.person_title,
                      practitioner.profile?.first_name,
                      practitioner.profile?.last_name
                    ].filter(Boolean).join(' ');
                    break;
                  case USER_ROLE_ID.PRIMARY_CARE_NURSE:
                    practitioner = d as PrimaryCareNurseModel;
                    name = [
                      practitioner.person_title,
                      practitioner.first_name,
                      practitioner.last_name
                    ].filter(Boolean).join(' ');
                    break;
                }
                return {
                  ...d,
                  id: d.user_id,
                  role_id: this.practitionerRoleId,
                  name
                };
              })
            );
          }
          console.error(err);
          return of(null);
        }),
        map(doc => doc ? [{
          isActive: true,
          label: [doc.name].filter(Boolean).join(' '),
          meta: [doc.email, doc.phone].filter(Boolean).join(', '),
          ...doc
        } as PractitionerSearchResult] : [{
          isActive: true,
          label: 'Practice Member Not Found',
          meta: 'N/A',
          id: this.practitionerId,
          role_id: this.practitionerRoleId,
        } as PractitionerSearchResult]),
        tap(([doc]) => this.practitioner = doc)
      ) : of([]),
      this.practitionerSearchTerm$.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => this.practitionersLoading = true),
        switchMap(term => this.practiceMemberHttpService.list(this.activeUserService.practiceId as number, {
          params: {
            search: term,
            per_page: '20',
            'filter[type]': 'practitioners',
            'filter[authorized]': 'true'
          }
        }).pipe(
          map(res => {
            this.practitionerPagination = res.meta.pagination;
            return res.data.map(doc => ({
              isActive: true,
              label: [doc.name].filter(Boolean).join(' '),
              meta: [doc.email, doc.phone].filter(Boolean).join(', '),
              ...doc
            }));
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.practitionersLoading = false)
        ))
      )
    ).pipe(
      map(data => data.reduce<PractitionerSearchResult[]>(
        (out, row) => {
          // remove duplicates
          if (!out.some(({ id }) => id === row.id)) {
            out.push(row);
          }
          return out;
        }, [])
      ),
      tap(() => this.loaded.emit(this.practitioner))
    );
    setTimeout(() => this.practitionerSearchTerm$.next(''), 0);
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  get practitioner(): PractitionerSearchResult | null {
    return this._practitioner;
  }

  set practitioner(val: PractitionerSearchResult | null) {  // this value is updated by programmatic
    if (val !== undefined && this._practitioner !== val) {
      this._practitioner = val
      this.onChange(val)
      this.onTouch(val)
    }
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.practitioner = value
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }
}