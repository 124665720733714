<div class="modal-body">
    <div class="text-center">
        <img src="./assets/images/dialogs/confirm-image.svg ">
    </div>
    <h3 class="title-text">{{title}}</h3>
    <div class="message-text">
        {{message}}
    </div>
    <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
<div class="modal-footer" loadingState="confirm-dialog-buttons">
    <div class="container-fluid text-center">
        <ng-container *ngIf="actions; else noActions">
            <button *ngFor="let action of actions"
                    type="button"
                    [ngClass]="action.buttonClass ? action.buttonClass : 'btn btn-default mr-1 ml-1'"
                    (click)="callAction(action.action)">{{ action.title }}</button>
        </ng-container>
        <ng-template #noActions>
            <button type="button" class="btn btn-default confirm-btn"
                    (click)="confirm()">{{ confirmButtonName }}</button>
            <button type="button" class="btn btn-default ml-2 confirm-btn"
                    (click)="activeModal.close()">{{ cancelButtonName }}</button>
        </ng-template>
    </div>
</div>
