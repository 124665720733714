import { Component, OnInit } from '@angular/core';
import { InvoiceModel } from '@epione/shared/models/invoice.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'epione-authorize-invoice-modal',
  templateUrl: './authorize-invoice-modal.component.html',
  styleUrls: ['./authorize-invoice-modal.component.scss']
})
export class AuthorizeInvoiceModalComponent implements OnInit {

  public authorizeInvoice$: Subject<boolean> = new Subject<boolean>();
  public invoice?: InvoiceModel;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
