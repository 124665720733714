import { Injectable } from '@angular/core';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { BasketModel } from '@epione/shared/models/basket.model';

@Injectable({
  providedIn: 'root'
})
export class BasketHttpService extends BillingHttpApiService<BasketModel, PaginatedResponse<BasketModel>> {
  protected resourcePath = 'settings/basket';

}
