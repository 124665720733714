import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@epione/shared/guards/auth.guard';
import { SettingsComponent } from './settings.component';
import { FinAccountMappingComponent } from './fin-account-mapping/fin-account-mapping.component';
import { MemberPreferencesComponent } from './member-preferences/member-preferences.component';
import { MedicalAidPracticeComponent } from "@epione/modules/settings/medical-aid-practice/medical-aid-practice.component";
import { BasketComponent } from '@epione/modules/settings/basket/basket.component';
import { BasketSaveComponent } from '@epione/modules/settings/basket/basket-save/basket-save.component';

export const routes: Routes = [
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: '/404', pathMatch: 'full' },
            { path: 'account-mapping', component: FinAccountMappingComponent },
            { path: 'members', component: MemberPreferencesComponent },
            { path: 'medical-aid-practice', component: MedicalAidPracticeComponent },
            { path: 'basket', component: BasketComponent },
            { path: 'basket/create', component: BasketSaveComponent },
            { path: 'basket/update/:id', component: BasketSaveComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SettingsRoutingModule { }
