<div class="modal-body">
    <div class="text-center">
      <img src="./assets/images/dialogs/error-image.svg">
    </div>
    <h3 class="text">Ooops!</h3>
    <div class="message" *ngFor="let message of messages">
      <div [innerHTML]="message"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="container-fluid text-center">
      <button type="button" class="btn btn-default" (click)="activeModal.close()">DONE</button>
    </div>
  </div>