export const USER_ROLE = {
    PATIENT: 'patient',
    HOSPITAL: 'hospital',
    SPECIALIST: 'specialist',
    GENERAL_PRACTITIONER: 'general_practitioner',
    MEDICAL_AID_ADMIN: 'medical_aid_administrator',
    PRACTICE_MANAGER: 'practice_manager',
    FIELD_WORKER: 'field_worker',
    FACILITY_MEMBER: 'facility_member',
    PRIMARY_CARE_NURSE: 'primary_care_nurse'
};

export const USER_ROLE_TITLES = {
    [USER_ROLE.PATIENT]: 'Patient',
    [USER_ROLE.HOSPITAL]: 'Hospital',
    [USER_ROLE.SPECIALIST]: 'Specialist',
    [USER_ROLE.GENERAL_PRACTITIONER]: 'General Practitioner',
    [USER_ROLE.MEDICAL_AID_ADMIN]: 'Medical Aid Administrator',
    [USER_ROLE.PRACTICE_MANAGER]: 'Practice Manager',
    [USER_ROLE.FIELD_WORKER]: 'Field Worker',
    [USER_ROLE.FACILITY_MEMBER]: 'Facility Member',
    [USER_ROLE.PRIMARY_CARE_NURSE]: 'Primary Care Nurse'
};

export const USER_ROLE_ID = {
    PATIENT: 2,
    HOSPITAL: 3,
    SPECIALIST: 4,
    GENERAL_PRACTITIONER: 5,
    MEDICAL_AID_ADMIN: 6,
    PRACTICE_MANAGER: 7,
    FIELD_WORKER: 8,
    GUEST: 9,
    FACILITY_MEMBER: 10,
    PRIMARY_CARE_NURSE: 11,
};

export type Role = 'patient'
    | 'hospital'
    | 'specialist'
    | 'general_practitioner'
    | 'medical_aid_administrator'
    | 'practice_manager'
    | 'field_worker'
    | 'guest'
    | 'facility_member'
    | 'primary_care_nurse';
