import { Injectable } from '@angular/core';
import { PaginatedResponse } from "@epione/shared/types/paginatedResponse";
import { MessageResponse } from "@epione/shared/types/messageResponse";
import { ErrorDialogService } from "@epione/shared/dialogs/error-dialog.service";
import { HttpClient } from '@angular/common/http';
import { HttpNestedApiService } from "@epione/shared/services/abstract/http/http-nested-api.service";
import { APP_CONFIG } from "@epione/shared/config/common.config";


@Injectable({
  providedIn: 'root'
})
export abstract class MainNestedHttpApiService<T = any, L = PaginatedResponse<T>, D = MessageResponse> extends HttpNestedApiService<T, L, D> {
  protected apiBasePath = `${APP_CONFIG.API_URL.main}/api`;

  constructor(
    protected http: HttpClient,
    protected errorDialogService: ErrorDialogService
  ) {
    super(http, errorDialogService);
  }
}
