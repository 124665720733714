import { Injectable } from '@angular/core';
import { PrimaryCareNurseModel } from '@epione/shared/models/main/primary-care-nurse.model';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { MainHttpApiService } from '../http/main-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class PrimaryCareNurseHttpService extends MainHttpApiService<PrimaryCareNurseModel, PaginatedResponse<PrimaryCareNurseModel>> {
    protected resourcePath: string = 'primary-care-nurse';
}
