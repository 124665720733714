import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, EMPTY, from } from 'rxjs';
import * as moment from 'moment';
import { take, catchError, switchMap, filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ActiveUserService } from '../services/global/active-user.service';
import { UserModel } from '../models/user.model';

/**
 * Period in Minutes before the expiry that the token should try to be refreshed
 *
 * @var number
 */
const TOKEN_REFRESH_GRACE_PERIOD: number = 5;

/**
 * Interceptor to handle refreshing the JWT token on or before expiry
 *
 * @export
 * @class TokenRefreshInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {
  /**
   * Header to signify this interceptor should be skipped
   *
   * @memberof TokenRefreshInterceptor
   */
  public skipHeader = 'Skip--Token-Refresh';

  /**
   * Refresh lock to only allow a single refresh to occur at a time
   *
   * @private
   * @memberof TokenRefreshInterceptor
   */
  private refreshTokenInProgress = false;

  /**
   * Subject to queue requests waiting for pending refresh to complete
   *
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof TokenRefreshInterceptor
   */
  private refreshTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * URL of token refresh endpoint
   *
   * @private
   * @type {string}
   * @memberof TokenRefreshInterceptor
   */
  private tokenRefreshUrl: string = `${environment.api.billing}/auth/user`;

  /**
   * Creates an instance of TokenRefreshInterceptor.
   * @param {ActiveUserService} activeUserService
   * @param {HttpClient} http
   * @memberof TokenRefreshInterceptor
   */
  constructor(
    private activeUserService: ActiveUserService,
    private http: HttpClient
  ) { }

  /**
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TokenRefreshInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // skip interceptor if the skip header is present of the token doensn't need renewal
    if (!req.headers.has(this.skipHeader) && this.testForRenewal() && !req.headers.has('Skip--All')) {
      return this.handleRefresh(req, next);
    }

    return next.handle(req).pipe(
      catchError(err => {
        // only handle 401 if its a token expiration response
        if (err.status === 401 && err.error.message === 'Token has expired') {
          return this.handleRefresh(req, next);
        }

        // only handle 401 blacklist if its a token that was blacklisted or not provided (no session)
        if (
          err.status === 401 &&
          [
            'Token not provided',
            'The token has been blacklisted'
          ].some(e => e === err.error.message)
        ) {
          this.activeUserService.logout();
          return EMPTY;
        }

        // only handle 409 if its a token blacklist response
        if (
          err.status === 409 &&
          [
            'Wrong number of segments',
            'The token has been blacklisted',
            'Token has expired and can no longer be refreshed'
          ].some(e => e === err.error.message)
        ) {
          this.activeUserService.logout();
          return EMPTY;
        }

        this.refreshTokenSubject.next(true);
        this.refreshTokenInProgress = false;

        return throwError(err);
      })
    );
  }

  /**
   * Handle the refresh opperation
   *
   * @private
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TokenRefreshInterceptor
   */
  private handleRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // dont do a refresh if its already in progress
    if (this.refreshTokenInProgress) {
      // enqueue reuqest to fire once refresh is complete
      return this.refreshTokenSubject
        .pipe(
          filter(result => result === true),
          take(1),
          switchMap(() => next.handle(req))
        );
    }

    // perform the refresh
    return from(this.renewToken()).pipe(
      switchMap(res => next.handle(req))
    );
  }

  /**
   * Test if the token needs to be renewed
   *
   * @private
   * @returns
   * @memberof TokenRefreshInterceptor
   */
  private testForRenewal(): boolean {
    // dont try renewals of logged out user
    if (!this.activeUserService.token) {
      return false;
    }

    // difference between expiry and now as duration (minutes)
    const claims = this.activeUserService.claims;
    if (claims) {
      const tte = moment.duration(
        moment.unix(claims.exp as number).diff(moment())
      ).minutes();
      console.log('[TOKEN RENEWAL]', claims.exp, moment.unix(claims.exp as number).toLocaleString(), moment().toLocaleString(), tte);

      // if its outside of the grace period then we don't want to renew yet
      if (tte > TOKEN_REFRESH_GRACE_PERIOD) {
        return false;
      }

      return true;
    }

    return false;
  }

  /**
   * Renew the token with the refresh endpoint
   *
   * @private
   * @returns
   * @memberof TokenRefreshInterceptor
   */
  private renewToken() {
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(false);

    return this.http
      .post<UserModel>(this.tokenRefreshUrl, null, {
        headers: new HttpHeaders({
          [this.skipHeader]: 'true',
          'Authorization': 'Bearer ' + this.activeUserService.token
        })
      })
      .toPromise<UserModel>()
      .then(res => {
        // update user token in storage
        if (this.activeUserService.token) {
          this.activeUserService.setToken(res['token'] as string);
        }

        // fire enqued requests
        this.refreshTokenSubject.next(true);
        this.refreshTokenInProgress = false;

        return res;
      });
  }
}
