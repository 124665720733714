import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@epione/shared/config/common.config';
import { ActiveUserService, InvalidIntegrationSetupError } from './shared/services/global/active-user.service';

@Component({
  selector: 'epione-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public appVersion: string = APP_CONFIG.APP_VERSION;
  public noPadding: boolean = false;
  public loading: boolean = true;

  constructor(
    private activeUserService: ActiveUserService,
    private router: Router
  ) {
    this.activeUserService.onInitialised((err) => {
      if (err instanceof InvalidIntegrationSetupError) {
        this.router.navigate(['/settings', 'account-mapping']);
      }
      this.loading = false;
    });
  }
}
