import { Injectable } from '@angular/core';
import { BillingNestedHttpApiService } from '@epione/shared/services/http/billing-nested-http-api.service';
import { PaymentModel } from '@epione/shared/models/payment.model';

@Injectable({
  providedIn: 'root'
})
export class InvoicePaymentHttpService extends BillingNestedHttpApiService<PaymentModel> {
  protected resourcePath = "invoices/{:parentId}/payments";
}
