<div class="container-fluid" [ngStyle]="{ 'display': loadingStatus === null ? 'block' : 'none' }"
     [formGroup]="invoiceForm">
    <ng-container *ngIf="invoiceLoaded">
        <div class="row">
            <div class="col">
                <h1 class="card-title text-epione text-uppercase font-weight-bold">
                    {{ invoiceData ? 'Update' : 'Capture' }} Invoice
                    <span *ngIf="invoiceData">:&nbsp;{{ invoiceData.invoice_number }}</span>
                </h1>
            </div>
            <div class="col-auto" *ngIf="invoiceData">
                <div class="text-muted text-right">Created: {{ invoiceData.created_at | date: 'medium' }}</div>
                <div class="text-muted text-right">Last Updated: {{ invoiceData.updated_at | date: 'medium' }}</div>
            </div>
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="media" *ngIf="practitioner;else selectDoctor">
                    <div class="media-image align-self-start mr-3 d-flex flex-column">
                        <img [src]="practitioner?.photo ? practitioner?.photo?.link : './assets/images/blank-avatar.png'"
                             onerror="this.src='./assets/images/blank-avatar.png';"
                             class="profile-image align-self-start mr-3">
                        <button epioneLoadingState="invoice-save" *ngIf="canEditDoctor && action === 'create'"
                                (click)="practitioner = null; account = null; lifecycle = null"
                                class="btn btn-primary btn-sm">
                            <mat-icon [inline]="true">edit</mat-icon>&nbsp;Change
                        </button>
                    </div>
                    <div class="media-body">
                        <p><strong>{{ practitioner?.name }}</strong></p>
                        <p>{{ practitioner?.email }}</p>
                        <p>{{ practitioner?.phone }}</p>
                    </div>
                </div>
                <ng-template #selectDoctor>
                    <epione-select-practitioner
                                                (loaded)="loadComplete('practitioner')"
                                                [practitionerId]="practitionerId"
                                                [practitionerRoleId]="practitionerRoleId"
                                                [(ngModel)]="practitioner"
                                                [ngModelOptions]="{standalone: true}">
                    </epione-select-practitioner>
                </ng-template>
            </div>
            <div class="col-md-4">
                <div class="media"
                     *ngIf="account && (practitioner || (action == 'update' && !practitionerId));else selectAccount">
                    <div class="media-image align-self-start mr-3 d-flex flex-column">
                        <img [src]="account.patient.photo && account.patient.photo.link ? account.patient.photo.link : './assets/images/blank-avatar.png'"
                             onerror="this.src='./assets/images/blank-avatar.png';"
                             class="profile-image align-self-start mr-3">
                        <button epioneLoadingState="invoice-save" *ngIf="action === 'create'"
                                (click)="account = null; lifecycle = null"
                                class="btn btn-primary btn-sm">
                            <mat-icon [inline]="true">edit</mat-icon>&nbsp;Change
                        </button>
                    </div>
                    <div class="media-body">
                        <p><strong>{{ account.patient.first_name }} {{ account.patient.last_name }}</strong></p>
                        <p>{{ account.email }}</p>
                        <p>{{ account.mobile_number }}</p>
                        <p>{{ account.patient.birthday ? 'DOB: '+account.patient.birthday : '' }}</p>
                        <div *ngIf="account.payment_method_id === 2">
                            <strong>MEDICAL AID</strong>
                            <div
                                 class="ml-1"
                                 [title]="account.medicalAidOption?.medicalAidPlan?.medicalAidScheme?.name + ' / ' + account.medicalAidOption?.medicalAidPlan?.name + ' / ' + account.medicalAidOption?.name">
                                Scheme: {{ account.medicalAidOption?.medicalAidPlan?.medicalAidScheme?.name }}
                            </div>
                            <div class="ml-1">Membership Number: {{ account.medical_aid_number }}</div>
                            <div class="ml-1">Dependant Code: {{ account.dependant_code }}</div>
                            <div class="ml-1">Main Member ID: {{ account.main_member_id }}</div>
                        </div>
                        <div *ngIf="dpcPatientFlag">
                            <strong>DPC PILOT STATUS</strong>
                            <div class="ml-2" *ngIf="dpcPatientFlag.data.in_arrears === false">
                                <strong style="color: #72bb53">Active</strong>
                            </div>
                            <div class="ml-2" *ngIf="dpcPatientFlag.data.in_arrears === true">
                                <ng-container
                                              *ngIf="!!dpcPatientFlag.data.latest_invoice_id && dpcPatientFlag.data.latest_invoice_id !== invoiceData?.id; else noLatestId">
                                    <a class="dpcLink"
                                       [routerLink]="['/invoice/update', dpcPatientFlag.data.latest_invoice_id]"
                                       title="Click to view latest outstanding invoice">
                                        <strong style="color: #b70f0a">Active In Arrears</strong>
                                    </a>
                                </ng-container>
                                <ng-template #noLatestId>
                                    <strong style="color: #b70f0a">Active In Arrears</strong>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #selectAccount>
                    <ng-container
                                  *ngIf="practitioner || (action == 'update' && !practitionerId && accountId); else noDoctor">
                        <epione-select-account
                                               (loaded)="loadComplete('account')"
                                               [accountId]="accountId"
                                               [(ngModel)]="account"
                                               [practitionerId]="practitioner?.id"
                                               [ngModelOptions]="{standalone: true}">
                        </epione-select-account>
                    </ng-container>
                    <ng-template #noDoctor>
                        <div class="form-group">
                            <label class="text-epione">
                                Please first select a practitioner to list the accounts for their respective patients
                            </label>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
            <div class="col-md-4">
                <ng-container
                              *ngIf="(practitioner && account) || (action == 'update' && !practitionerId && account); else noAccount">
                    <epione-select-case
                                        epioneLoadingState="invoice-save"
                                        (loaded)="loadComplete('case')"
                                        [caseId]="caseId"
                                        [lifecycleId]="lifecycleId"
                                        [(ngModel)]="lifecycle"
                                        [disabled]="!!invoiceForm.get('case_lifecycle_id')?.disabled"
                                        [patientId]="account.patient_id"
                                        [practitionerId]="practitioner?.id"
                                        [practitionerRoleId]="practitioner?.role_id"
                                        [ngModelOptions]="{standalone: true}">
                    </epione-select-case>
                    <label *ngIf="!lifecycle" class="text-epione">
                        Alternatively specify an invoice date if billing for non case related items.
                    </label>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="invoice-date" class="form-label">Invoice Date</label>
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       epioneLoadingState="invoice-save"
                                       ngbDatepicker
                                       #invoiceDatePicker="ngbDatepicker"
                                       id="invoice-date"
                                       formControlName="invoice_date">
                                <div class="input-group-prepend" (click)="invoiceDatePicker.toggle()">
                                    <div class="input-group-text">
                                        <span class="material-icons">calendar_today</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 form-group">
                            <label for="invoice-date" class="form-label">Due Date</label>
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       epioneLoadingState="invoice-save"
                                       ngbDatepicker
                                       #dueDatePicker="ngbDatepicker"
                                       id="invoice-date"
                                       formControlName="due_date">
                                <div class="input-group-prepend" (click)="dueDatePicker.toggle()">
                                    <div class="input-group-text">
                                        <span class="material-icons">calendar_today</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noAccount>
                    <div class="form-group">
                        <label class="text-epione">
                            Please first select a practitioner & account to invoice
                        </label>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="invoice-table table table-hover mt-5">
                        <thead>
                            <tr class="text-epione text-uppercase">
                                <th></th>
                                <th></th>
                                <!--                                <th style="min-width: 165px" scope="col">Line Type</th>-->
                                <th style="min-width: 300px" scope="col">Service Date</th>
                                <th style="min-width: 300px" scope="col">Procedure Code</th>
                                <th style="min-width: 300px" scope="col">NAPPI Code</th>
                                <th style="min-width: 300px" scope="col">ICD10 Code(s)</th>
                                <th style="min-width: 90px" scope="col">Quantity</th>
                                <th style="min-width: 160px" scope="col">Unit Amount</th>
                                <th style="min-width: 160px" scope="col">Discount</th>
                                <th style="min-width: 160px" scope="col">Line Total</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                            <ng-container formArrayName="line_items">
                                <ng-container *ngFor="let control of lineItemsFormArray.controls; let i = index;"
                                              [formGroupName]="i">
                                    <ng-container
                                                  *ngIf="lineItemIsVisible(control)">
                                        <tr cdkDrag>
                                            <td class="drag-handle">
                                                <mat-icon cdkDragHandle>drag_indicator</mat-icon>
                                            </td>
                                            <td>
                                                <button class="btn btn-info btn-expandRow"
                                                        [ngClass]="{ 'invalid': !!(control.get('description')?.touched && control.get('description')?.invalid) }"
                                                        epioneLoadingState="invoice-save"
                                                        (click)="toggleExpandedRow(i)">
                                                    <mat-icon [inline]="true" style="font-size: 1.45em">
                                                        edit_note
                                                    </mat-icon>
                                                </button>
                                            </td>
                                            <!--                                            <td>-->
                                            <!--                                                <select id="type-code"-->
                                            <!--                                                        class="form-control"-->
                                            <!--                                                        epioneLoadingState="invoice-save"-->
                                            <!--                                                        epioneFormRequired-->
                                            <!--                                                        formControlName="line_item_type_id">-->
                                            <!--                                                    <option [value]="null" selected disabled>Select type code</option>-->
                                            <!--                                                    <option *ngFor="let type of lineItemTypes" [value]="type.id">-->
                                            <!--                                                        {{ type.name }}-->
                                            <!--                                                    </option>-->
                                            <!--                                                </select>-->
                                            <!--                                            </td>-->
                                            <td>
                                                <div class="input-group">
                                                    <input type="text" class="form-control"
                                                           epioneLoadingState="invoice-save"
                                                           ngbDatepicker
                                                           container="body"
                                                           #serviceDatePicker="ngbDatepicker"
                                                           formControlName="service_date">
                                                    <div class="input-group-prepend"
                                                         (click)="serviceDatePicker.toggle()">
                                                        <div class="input-group-text">
                                                            <span class="material-icons">calendar_today</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control"
                                                       epioneLoadingState="invoice-save"
                                                       placeholder="Procedure Code"
                                                       id="tariff-code"
                                                       epioneFormRequired
                                                       formControlName="tariff_code_txt">
                                            </td>
                                            <td>
                                                <epione-select-nappi epioneLoadingState="invoice-save"
                                                                     formControlName="nappi_code_id">
                                                </epione-select-nappi>
                                            </td>
                                            <td>
                                                <epione-select-icd10 epioneLoadingState="invoice-save"
                                                                     formControlName="icd10_codes">
                                                </epione-select-icd10>
                                            </td>
                                            <td>
                                                <input type="number" class="form-control"
                                                       epioneLoadingState="invoice-save"
                                                       placeholder="Quantity"
                                                       min="0"
                                                       id="quantity"
                                                       epioneFormRequired
                                                       formControlName="quantity">
                                            </td>
                                            <td>
                                                <epione-currency-input
                                                                       epioneFormRequired
                                                                       [currency]="currency"
                                                                       formControlName="unit_amount">
                                                </epione-currency-input>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">%</div>
                                                    </div>
                                                    <input type="number"
                                                           class="form-control"
                                                           epioneLoadingState="invoice-save"
                                                           min="1"
                                                           max="100"
                                                           formControlName="discount_rate">
                                                </div>
                                            </td>
                                            <td>
                                                <epione-currency-input
                                                                       epioneFormRequired
                                                                       [disabled]="true"
                                                                       [currency]="currency"
                                                                       [ngModel]="getLineAmount(control)"
                                                                       [ngModelOptions]="{standalone: true}">
                                                </epione-currency-input>
                                            </td>
                                            <td>
                                                <button class="btn btn-danger" (click)="removeLineItem(i)"
                                                        epioneLoadingState="invoice-save"
                                                        *epioneInvoiceIncomplete="[invoiceData?.invoice_status_id, true]">Remove</button>
                                            </td>
                                        </tr>
                                        <tr *ngIf="expandedRows.includes(i)" class="expandedRow">
                                            <td></td>
                                            <th>Description</th>
                                            <td colspan="3">
                                                <textarea class="form-control" rows="4"
                                                          formControlName="description"></textarea>
                                            </td>
                                        </tr>

                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <tr class="text-center" *ngIf="!lineItemsFormArray.controls.length">
                                <td colspan="9">No Line Items To Display</td>
                            </tr>

                            <tr>
                                <td colspan="6"></td>
                                <th class="text-epione text-uppercase text-right">
                                    Total:
                                </th>
                                <td>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                        </div>
                                        <input type="number"
                                               class="form-control"
                                               epioneLoadingState="invoice-save"
                                               min="1"
                                               max="100"
                                               [ngModelOptions]="{standalone: true}"
                                               [(ngModel)]="totalDiscount"
                                               (ngModelChange)="applyTotalDiscount($event)">
                                    </div>
                                </td>
                                <td>
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="total"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </td>
                            </tr>

                            <ng-container *epioneInvoiceComplete="invoiceData?.invoice_status_id">
                                <tr>
                                    <td colspan="6" class="border-top-0"></td>
                                    <th class="text-epione text-uppercase text-right border-top-0">
                                        Amount Paid:
                                    </th>
                                    <td class="border-top-0">
                                        <strong>{{ invoiceData?.amount_paid | currency: currency }}</strong>
                                    </td>
                                    <td class="border-top-0">
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="border-top-0"></td>
                                    <th class="text-epione text-uppercase text-right border-top-0">
                                        Amount Due:
                                    </th>
                                    <td class="border-top-0">
                                        <strong>{{ invoiceData?.amount_due | currency: currency }}</strong>
                                    </td>
                                    <td class="border-top-0">
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-center"
                     *epioneInvoiceIncomplete="[invoiceData?.invoice_status_id, true]">
                    <button class="btn btn-primary bg-epione mr-3" (click)="addLineItem()"
                            epioneLoadingState="invoice-save">
                        + Add Line Item
                    </button>
                    <button class="btn btn-primary bg-epione" (click)="addBasket()" epioneLoadingState="invoice-save">
                        + Add Basket
                    </button>
                </div>
                <div class="epione-line mt-3 mb-3"></div>
                <div class="d-flex justify-content-end">
                    <ng-container *epioneInvoiceIncomplete="[invoiceData?.invoice_status_id, true]">
                        <button class="btn btn-primary mx-3" (click)="submitInvoice(false)"
                                epioneLoadingState="invoice-save">
                            {{ action === 'create' ? 'Create' : 'Save' }} Invoice
                        </button>
                        <button *ngIf="action !== 'create'" class="btn btn-primary mx-3" (click)="submitInvoice(true)"
                                epioneLoadingState="invoice-save">
                            Save & Authorize Invoice
                        </button>
                    </ng-container>
                    <ng-container *epioneInvoiceComplete="invoiceData?.invoice_status_id">
                        <button class="btn btn-primary mx-3"
                                *ngIf="invoiceData && invoiceData.amount_due > 0"
                                (click)="trackPayment()"
                                epioneLoadingState="invoice-save">
                            Allocate Payment
                        </button>
                        <button class="btn btn-primary mx-3" *ngIf="invoiceData"
                                (click)="sendInvoice()"
                                epioneLoadingState="invoice-save">
                            {{ invoiceData?.sent ? 'Re-Send' : 'Send' }} Invoice
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div [ngStyle]="{ 'display': loadingStatus === null ? 'none' : 'block' }">
    <div class="">
        <loader [message]="loadingStatus"></loader>
    </div>
</div>