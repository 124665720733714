import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaseLifecycleModel } from '@epione/shared/models/case-lifecycle.model';
import { CaseModel } from '@epione/shared/models/main/case.model';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { CaseHttpService } from '@epione/shared/services/main/case.service';
import { CaseStageAlias, CaseStageStatusAlias } from '@epione/shared/types/case.enum';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { RoleId } from '@epione/shared/types/role.enum';
import { concat, merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

// map for which roles are allowed to bill for which stages
const BILLABLE_STAGES = {
  [RoleId.GP]: [
    CaseStageAlias.APPOINTMENT_PERFORM,
    CaseStageAlias.EXIT_MEETING_PERFORM,
  ],
  [RoleId.SP]: [
    CaseStageAlias.CONSULTATION_PERFORM,
    CaseStageAlias.FOLLOW_UP_MEETING_PERFORM,
    CaseStageAlias.PROCEDURE_PERFORM
  ],
  [RoleId.PCN]: [
    CaseStageAlias.PRIMARY_NURSE_CONSULT_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_1_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_2_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_3_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_4_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_5_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_6_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_7_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_8_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_9_PERFORM,
    CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_10_PERFORM,
  ],
};

// Add in any stages that count as 'complete' here for the above allowed lists
const COMPLETED_STAGE_STATUSES = [
  CaseStageStatusAlias.IN_PROGRESS,
  CaseStageStatusAlias.TO_DO,
  CaseStageStatusAlias.PERFORMED
];

const BILLABLE_LIFECYCLE_MAP: { [key: string]: string } = {
  // GP
  [CaseStageAlias.APPOINTMENT_SCHEDULE]: CaseStageAlias.APPOINTMENT_PERFORM,
  [CaseStageAlias.APPOINTMENT_PERFORM]: CaseStageAlias.APPOINTMENT_PERFORM,
  [CaseStageAlias.EXIT_MEETING_SCHEDULE]: CaseStageAlias.EXIT_MEETING_PERFORM,
  [CaseStageAlias.EXIT_MEETING_PERFORM]: CaseStageAlias.EXIT_MEETING_PERFORM,
  // SP
  [CaseStageAlias.CONSULTATION_SCHEDULE]: CaseStageAlias.CONSULTATION_PERFORM,
  [CaseStageAlias.CONSULTATION_PERFORM]: CaseStageAlias.CONSULTATION_PERFORM,
  [CaseStageAlias.FOLLOW_UP_MEETING_SCHEDULE]: CaseStageAlias.FOLLOW_UP_MEETING_PERFORM,
  [CaseStageAlias.FOLLOW_UP_MEETING_PERFORM]: CaseStageAlias.FOLLOW_UP_MEETING_PERFORM,
  [CaseStageAlias.PROCEDURE_SCHEDULE]: CaseStageAlias.PROCEDURE_PERFORM,
  [CaseStageAlias.PROCEDURE_PERFORM]: CaseStageAlias.PROCEDURE_PERFORM,
  // PCN
  [CaseStageAlias.PRIMARY_NURSE_CONSULT_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_CONSULT_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_CONSULT_PERFORM]: CaseStageAlias.PRIMARY_NURSE_CONSULT_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_1_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_1_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_1_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_1_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_2_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_2_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_2_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_2_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_3_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_3_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_3_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_3_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_4_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_4_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_4_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_4_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_5_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_5_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_5_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_5_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_6_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_6_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_6_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_6_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_7_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_7_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_7_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_7_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_8_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_8_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_8_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_8_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_9_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_9_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_9_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_9_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_10_SCHEDULE]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_10_PERFORM,
  [CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_10_PERFORM]: CaseStageAlias.PRIMARY_NURSE_FOLLOW_UP_10_PERFORM,

};

const INCLUDES = [
  'flow',
  'status',
  'last_stage',
  'last_status',
  'lifecycle.stage',
  'lifecycle.status',
  'patient',
].join(',');

interface CaseResult {
  label: string | null;
  value: CaseLifecycleModel | null;
}

@Component({
  selector: 'epione-select-case',
  templateUrl: './select-case.component.html',
  styleUrls: ['./select-case.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectCaseComponent), multi: true }
  ]
})
export class SelectCaseComponent implements OnInit, ControlValueAccessor {
  @Input('epioneLoadingState') loadingAlias?: string;
  @Input() caseId?: number;
  @Input() lifecycleId?: number;
  @Input() patientId!: number;
  @Input() practitionerId?: number;
  @Input() practitionerRoleId?: number;
  @Output() loaded: EventEmitter<CaseLifecycleModel | null> = new EventEmitter<CaseLifecycleModel | null>();
  public caseSearchTerm$: Subject<string> = new Subject<string>();
  public casesLoading: boolean = false;
  public cases$!: Observable<CaseResult[]>;
  public casePagination!: Pagination;
  public disabled?: boolean;

  public _case: CaseLifecycleModel | null = null;
  private _disabled?: boolean;
  constructor(
    private activeUserService: ActiveUserService,
    private caseHttpService: CaseHttpService,
    private loadingService: LoadingStateService
  ) { }

  ngOnInit(): void {
    if (this.loadingAlias) {
      this.loadingService.on(this.loadingAlias).subscribe({
        next: (loading) => {
          if (loading) {
            this._disabled = this.disabled;
            this.disabled = loading;
          } else {
            this.disabled = this._disabled;
          }
        }
      })
    }
    this.cases$ = concat<CaseResult[]>(
      // default items
      this.caseId ? this.caseHttpService.find(this.caseId, {
        params: {
          include: INCLUDES
        }
      }).pipe(
        tap(c => {
          // resolve the current lifecycleId value to a proper billable stage lifecycle id
          if (this.lifecycleId) {
            const lifecycle = c.lifecycle!.find(ls => ls.id === this.lifecycleId);
            if (lifecycle) {
              const billableStage: string | undefined = BILLABLE_LIFECYCLE_MAP[lifecycle.stage!.alias];
              if (billableStage === lifecycle.stage!.alias) {
                // if its the same stage, we are already fine
                return;
              }
              if (billableStage) {
                const billableLifecycle = c.lifecycle!.find(ls => ls.stage!.alias === billableStage);
                if (billableLifecycle) {
                  this.lifecycleId = billableLifecycle.id;
                  return;
                }
              }
            }
          }

          // clear if not resolved
          this.lifecycleId = undefined;
          return;
        }),
        map(c => this.parseCaseToLifecycles(c).filter(ls => {
          return this.lifecycleId ? ls.value!.id === this.lifecycleId : true
        })),
        tap((list) => {
          if (list[0]) {
            console.log('loaded');
            this.case = list[0].value;
          }
        })
      ) : of([]),
      this.caseSearchTerm$.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => this.casesLoading = true),
        switchMap(term => this.caseHttpService.list({
          params: {
            search: term,
            per_page: '20',
            'filter[patient_id]': `${this.patientId}`,
            'filter[member_id]': `${this.practitionerId},${this.practitionerRoleId}`,
            include: INCLUDES
          }
        }).pipe(
          map(res => {
            this.casePagination = res.meta.pagination;
            return res.data.reduce<CaseResult[]>((out, row) => {
              return [
                ...out,
                ...this.parseCaseToLifecycles(row)
              ];
            }, [])
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.casesLoading = false)
        ))
      )
    ).pipe(
      map(data => data.reduce<CaseResult[]>(
        (out, row) => {
          // remove duplicates
          if (!out.some(({ value }) => value && value.id === row.value!.id)) {
            out = [...out, row];
          }
          return out;
        }, [{ label: 'N/A - Not Case Related Billing', value: null }])
      ),
      tap(() => this.loaded.emit(this.case))
    );

    if (!this.caseId) {
      setTimeout(() => this.caseSearchTerm$.next(''), 0);
    }
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  get case(): CaseLifecycleModel | null {
    return this._case;
  }

  set case(val: CaseLifecycleModel | null) {  // this value is updated by programmatic
    if (val !== undefined && this._case !== val) {
      this._case = val
      this.onChange(val)
      this.onTouch(val)
    }
  }

  compareFn(item: any, selected: any) {
    return item.value && selected && item.value.id === selected.id;
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.case = value
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  private parseCaseToLifecycles(data: CaseModel): CaseResult[] {
    // only allowed stages are per user role
    const allowedStages = BILLABLE_STAGES[this.practitionerRoleId as RoleId.GP | RoleId.SP | RoleId.PCN];
    return data.lifecycle ? data.lifecycle.filter(lifecycle => {
      // must be allowed in list per role
      return allowedStages.includes(`${lifecycle.stage?.alias}` as CaseStageAlias)
        // must be completed
        && COMPLETED_STAGE_STATUSES.includes(`${lifecycle.status?.alias}` as CaseStageStatusAlias)
    }).map<CaseResult>(lifecycle => ({
      label: null,
      value: {
        // finally convert from main system CaseModel into CaseLifecycleModel
        ...lifecycle,
        // map main system 'DateModel' to Billing UI string format
        ...{ created_at: data.created_at.date, updated_at: data.updated_at.date },
        case: { ...data, created_at: data.created_at.date, updated_at: data.updated_at.date }
      }
    })) : [];
  }
}