import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { InvoiceModel } from '@epione/shared/models/invoice.model';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { InvoicePaymentHttpService } from '../../services/http/invoice-payment-http.service';
import * as moment from 'moment';

enum PaymentType {
  cash = 'cash',
  card = 'card',
  eft = 'eft'
}

@Component({
  selector: 'epione-track-payment-modal',
  templateUrl: './track-payment-modal.component.html',
  styleUrls: ['./track-payment-modal.component.scss']
})
export class TrackPaymentModalComponent implements OnInit, OnDestroy {

  public invoice!: InvoiceModel;
  public complete$: Subject<boolean> = new Subject<boolean>();
  public paymentForm!: FormGroup;
  private typeSub?: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private loadingStateService: LoadingStateService,
    private paymentHttpService: InvoicePaymentHttpService,
    private errorDialogService: ErrorDialogService
  ) {
    this.loadPaymentForm();
  }

  get paymentType(): string | null {
    return this.paymentForm.get('type')?.value;
  }

  ngOnInit(): void {
  }

  loadPaymentForm() {
    this.paymentForm = this.fb.group({
      type: [null, [Validators.required]],
      payment_date: [moment().toDate(), Validators.required],
      amount: [null, [Validators.required, Validators.min(1)]],
      note: [null]
    });
    if (this.typeSub) {
      this.typeSub.unsubscribe();
    }
    this.typeSub = this.paymentForm.get('type')?.valueChanges.pipe(
      tap(type => {
        if (type === PaymentType.eft) {
          // set amount to null and not required
          this.paymentForm.get('amount')?.setValidators(null);
          this.paymentForm.get('amount')?.patchValue(null);
          // set note to required and pre-populate
          this.paymentForm.get('note')?.setValidators([Validators.required, Validators.min(3)]);
          this.paymentForm.get('note')?.patchValue('EFT Payment Pending.');
        } else {
          // set amount to required
          this.paymentForm.get('amount')?.setValidators([Validators.required, Validators.min(1)]);
          // set note to not required and empty
          this.paymentForm.get('note')?.setValidators(null);
          this.paymentForm.get('note')?.patchValue(null);
        }
        this.paymentForm.get('amount')?.updateValueAndValidity();
        this.paymentForm.get('note')?.updateValueAndValidity();
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.typeSub?.unsubscribe();
  }

  cancel() {
    if (this.paymentType) {
      this.loadPaymentForm();
      return;
    }
    this.modal.close();
  }

  submitPayment() {
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      return;
    }
    this.loadingStateService.start('payment-track');
    this.paymentHttpService.create(this.invoice.id, this.paymentForm.getRawValue())
      .pipe(
        take(1),
        catchError(err => {
          this.errorDialogService.showErrorDialogFromResponse(err);
          return throwError(err);
        }),
        finalize(() => this.loadingStateService.end('payment-track'))
      ).subscribe({
        next: () => {
          this.complete$.next(true);
          this.modal.close();
        }
      });
  }

}
