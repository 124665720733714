import { Injectable } from '@angular/core';
import { BillingNestedHttpApiService } from '@epione/shared/services/http/billing-nested-http-api.service';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { HttpClient } from '@angular/common/http';
import { BillingAddressModel } from "@epione/shared/models/billing-address.model";

@Injectable({
  providedIn: 'root'
})
export class AccountsAddressesHttpService extends BillingNestedHttpApiService<BillingAddressModel> {
  protected resourcePath = 'accounts/{:parentId}/addresses';

  constructor(protected http: HttpClient, protected errorDialogService: ErrorDialogService) {
    super(http, errorDialogService);
  }
}
