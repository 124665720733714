import { Injectable } from '@angular/core';
import { MainHttpApiService } from "@epione/shared/services/http/main-http-api.service";
import { MedicalAidPlanModel } from "@epione/shared/models/main/medical-aid-plan.model";


@Injectable({
  providedIn: 'root'
})
export class MedicalAidPlansService extends MainHttpApiService<MedicalAidPlanModel, MedicalAidPlanModel[]> {
  protected resourcePath = 'medical-aid/plan';
}
