<div class="modal-header">
    <h1 class="modal-title text-epione font-weight-bold">Associate Account</h1>
</div>
<div class="modal-body" [formGroup]="guarantorFormGroup">
    <p>
        Associating this account ({{ getAccountDetails() }}), you are applying the rule that the below guarantor account
        is responsible for payment of this account. Do NOT associate accounts unless you have confirmed with the patient
        and guarantor that the details and information are correct.
    </p>
    <div class="row mb-3">
        <div class="col-12">
            <div class="form-group">
                <label for="account-number" class="text-epione">
                    Enter the ENET account number of guarantor:
                </label>
                <div class="associate-acc-input">
                    <input type="text" class="form-control" id="account-number"
                           formControlName="guarantor_account_number"
                           placeholder="Enter epione.net Account Number..."
                           epioneLoadingState="associate-account"
                           epioneFormRequired>
                    <mat-icon *ngIf="accountNumberMatch !== null && accountNumberMatch" [ngStyle]="{'color':'green'}">
                        done</mat-icon>
                    <mat-icon *ngIf="accountNumberMatch !== null && !accountNumberMatch" [ngStyle]="{'color':'red'}">
                        close</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label for="account-number-verify" class="text-epione">
                    Confirm the ENET account number of the guarantor:
                </label>
                <div class="associate-acc-input">
                    <input type="text" class="form-control" id="account-number-verify"
                           formControlName="guarantor_account_number_confirm"
                           placeholder="Re-enter epione.net Account Number..."
                           epioneLoadingState="associate-account"
                           epioneFormRequired>
                    <mat-icon *ngIf="accountNumberMatch !== null && accountNumberMatch" [ngStyle]="{'color':'green'}">
                        done</mat-icon>
                    <mat-icon *ngIf="accountNumberMatch !== null && !accountNumberMatch" [ngStyle]="{'color':'red'}">
                        close</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label for="mobile-number" class="text-epione">
                    Enter the registered mobile phone number of the guarantor as per their epione.net account:
                </label>
                <div class="associate-acc-input">
                    <input type="text" class="form-control" id="mobile-number"
                           formControlName="guarantor_mobile_number"
                           placeholder="Enter guarantor's mobile phone number..."
                           epioneLoadingState="associate-account"
                           epioneFormRequired>
                    <mat-icon *ngIf="mobileNumberMatch !== null && mobileNumberMatch" [ngStyle]="{'color':'green'}">done
                    </mat-icon>
                    <mat-icon *ngIf="mobileNumberMatch !== null && !mobileNumberMatch" [ngStyle]="{'color':'red'}">close
                    </mat-icon>
                </div>
                <span *ngIf="mobileNumberMatch !== null && !mobileNumberMatch" [ngStyle]="{'color':'red'}">
                    Mobile phone does not match account number entered. Please check account records to confirm.
                </span>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label for="mobile-number-verify" class="text-epione">
                    Confirm the mobile phone number of the guarantor:
                </label>
                <div class="associate-acc-input">
                    <input type="text" class="form-control" id="mobile-number-verify"
                           formControlName="guarantor_mobile_number_confirm"
                           placeholder="Re-enter guarantor's mobile phone number..."
                           epioneLoadingState="associate-account"
                           epioneFormRequired>
                    <mat-icon *ngIf="mobileNumberMatch !== null && mobileNumberMatch" [ngStyle]="{'color':'green'}">done
                    </mat-icon>
                    <mat-icon *ngIf="mobileNumberMatch !== null && !mobileNumberMatch" [ngStyle]="{'color':'red'}">close
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary associate-acc-btn"
            epioneLoadingState="associate-account"
            (click)="activeModal.close('done')">
        Cancel
    </button>
    <button type="button"
            class="btn btn-primary associate-acc-btn"
            epioneLoadingState="associate-account"
            (click)="associateAccount()">Associate
    </button>
</div>

<ng-template #guarantorConfirmTmpl>
    <div class="text-center">
        <p>
            You are about to associate the following account:<br>
            <b>{{ account.patient?.first_name + ' ' + account.patient?.last_name }}</b><br>
            <b>Account No.: {{ account.account_number }}</b>
        </p>
        <p>
            to the primary (guarantor) account
        </p>
        <p>
            <b>{{ guarantorAccount?.patient?.first_name + ' ' + guarantorAccount?.patient?.last_name }}</b><br>
            <b>Account No.: {{ guarantorAccount?.account_number }}</b><br>
            <b>Mobile Phone: {{ guarantorAccount?.mobile_number }}</b>
        </p>
        <p>
            Check that the above information is correct before finalising this association. Are you sure you want to
            associate the above accounts as described?
        </p>
    </div>
</ng-template>