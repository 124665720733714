import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { ProviderAccountModel } from '@epione/shared/models/provider-account.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderAccountHttpService extends BillingHttpApiService<ProviderAccountModel, ProviderAccountModel[]> {
  protected resourcePath = "settings/provider-accounts";
  private providerAccounts: ProviderAccountModel[] = [];
  private activeRequest?: Subject<ProviderAccountModel[]>;

  constructor(
    protected http: HttpClient,
    protected errorDialogService: ErrorDialogService
  ) {
    super(http, errorDialogService);
  }

  public getProviderAccountList(): Observable<ProviderAccountModel[]> {
    if (!this.providerAccounts.length) {
      if (!this.activeRequest) {
        return this.list().pipe(
          switchMap(res => {
            this.activeRequest = new Subject<ProviderAccountModel[]>();
            this.providerAccounts = res;
            this.activeRequest.next(res);
            this.activeRequest.complete();
            return of(this.providerAccounts);
          })
        );
      }
      return this.activeRequest.asObservable();
    }
    return of(this.providerAccounts);
  }
}
