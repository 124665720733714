import { Injectable } from '@angular/core';
import { ICD10CodeModel } from '@epione/shared/models/codes/icd10.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';

@Injectable({
  providedIn: 'root'
})
export class Icd10HttpService extends BillingHttpApiService<ICD10CodeModel, PaginatedResponse<ICD10CodeModel>> {
  protected resourcePath = "codes/icd10";
}