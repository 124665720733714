import { Injectable } from '@angular/core';
import { SpecialistModel } from '@epione/shared/models/main/specialist.model';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { MainHttpApiService } from '../http/main-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class SpecialistHttpService extends MainHttpApiService<SpecialistModel, PaginatedResponse<SpecialistModel>> {
    protected resourcePath: string = 'specialists';
}
