import { Injectable } from '@angular/core';
import { PracticeMemberModel } from '@epione/shared/models/main/practice-member.model';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { MainNestedHttpApiService } from '../http/main-nested-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class PracticeMemberHttpService extends MainNestedHttpApiService<PracticeMemberModel, PaginatedResponse<PracticeMemberModel>> {
    protected resourcePath: string = 'practice/{:parentId}/member';
}
