import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';



@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ErrorDialogComponent,
    SuccessDialogComponent,
    WarningDialogComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DialogsModule { }
