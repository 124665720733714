import { Component, OnInit, TemplateRef } from '@angular/core';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ConfirmModalAction } from '@epione/shared/dialogs/confirm-dialog.service';

@Component({
  selector: 'epione-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public subject?: Subject<any>;
  public title = 'Confirmation';
  public message = 'Are you sure?';
  public content: TemplateRef<any> | null = null;
  public confirmButtonName: string = 'YES';
  public cancelButtonName: string = 'NO';
  public actions?: ConfirmModalAction[];
  constructor(
    private loading: LoadingStateService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  public confirm(): void {
    this.loading.start('confirm-dialog-buttons');
    if (this.subject) {
      this.subject.next(true);
      this.activeModal.close();
    }
    this.loading.end('confirm-dialog-buttons');
  }

  public callAction(action: Function) {
    if (action()) {
      this.activeModal.close();
    }
  }
}
