import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Interceptor to add standard headers to requests
 *
 * @export
 * @class BaseInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class BaseInterceptor implements HttpInterceptor {
    /**
     * Header to signify this interceptor should be skipped
     *
     * @type {string}
     * @memberof BaseInterceptor
     */
    public skipHeader: string = 'Skip--Base';

    /**
     * Standard headers to add to each request if note present
     * @private
     * @type {{ [key: string]: string }}
     * @memberof BaseInterceptor
     */
    private stdHeaders: { [key: string]: string } = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    /**
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof BaseInterceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // skip interceptor
        if (!req.headers.has(this.skipHeader) && !req.headers.has('Skip--All')) {
            return next.handle(
                req.clone({
                    setHeaders: this.getNewHeaders(req)
                })
            );
        }

        return next.handle(req);
    }

    /**
     * retrieve the headers to be added to the request
     * filters to only those headers not already present in the request
     *
     * @param {HttpRequest<any>} req
     * @returns
     * @memberof BaseInterceptor
     */
    getNewHeaders(req: HttpRequest<any>) {
        return Object.keys(this.stdHeaders).reduce<{ [key: string]: string }>((newHeaders, headerKey) => {
            // skip content type addition entirely if sending FormData, let it generate its boundary
            if (req.body instanceof FormData && headerKey === 'Content-Type') {
                return newHeaders;
            }

            // only set stdHeader if the request hasnt set their own header fot this key
            if (!req.headers.has(headerKey)) {
                newHeaders[headerKey] = this.stdHeaders[headerKey];
            }

            return newHeaders;
        }, {});
    }
}
