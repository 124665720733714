<div class="container-fluid" [formGroup]="basketForm">
    <ng-container *ngIf="basketLoaded; else loadingTmpl">
        <div class="row mb-4">
            <div class="col-md-6">
                <h1 class="text-epione text-uppercase font-weight-bold">
                    {{ basketData ? 'Edit' : 'Create' }} Basket</h1>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-auto">
                <p>Basket Name:</p>
            </div>
            <div class="col">
                <input type="text"
                       class="form-control"
                       placeholder="Type here..."
                       epioneFormRequired
                       formControlName="title"
                       epioneLoadingState="basket-save">
            </div>
            <div class="col-auto">
                <button class="btn text-light btn-primary bg-epione px-3 float-right"
                        (click)="submitBasket()"
                        epioneLoadingState="basket-save">Save Basket
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-hover mt-5 basket-table">
                        <thead>
                            <tr class="text-epione text-uppercase">
                                <th scope="col-auto"></th>
                                <th style="min-width: 300px" scope="col">Procedure Code</th>
                                <th style="min-width: 300px" scope="col">ICD10 Code</th>
                                <th style="min-width: 300px" scope="col">Nappi Code</th>
                                <th style="min-width: 90px" scope="col">Quantity</th>
                                <th style="min-width: 160px" scope="col">Unit Amount</th>
                                <th style="min-width: 160px" scope="col">Line Item Total</th>
                                <th scope="col-auto"></th>
                            </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                            <ng-container formArrayName="basket_items">
                                <ng-container *ngFor="let control of basketItemsFormArray.controls; let i = index;"
                                              [formGroupName]="i">
                                    <tr cdkDrag>
                                        <td class="drag-handle">
                                            <mat-icon cdkDragHandle>drag_indicator</mat-icon>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control"
                                                   epioneLoadingState="basket-save"
                                                   placeholder="Tariff Code"
                                                   id="tariff-code"
                                                   epioneFormRequired
                                                   formControlName="tariff_code_txt">
                                        </td>
                                        <td>
                                            <epione-select-icd10 epioneLoadingState="basket-save"
                                                                 formControlName="icd10_codes">
                                            </epione-select-icd10>
                                        </td>
                                        <td>
                                            <epione-select-nappi epioneLoadingState="basket-save"
                                                                 formControlName="nappi_code_id">
                                            </epione-select-nappi>
                                        </td>
                                        <td>
                                            <input type="number" class="form-control"
                                                   epioneLoadingState="basket-save"
                                                   placeholder="Quantity"
                                                   min="0"
                                                   id="quantity"
                                                   epioneFormRequired
                                                   formControlName="quantity">
                                        </td>
                                        <td>
                                            <epione-currency-input
                                                                   epioneFormRequired
                                                                   [currency]="currency"
                                                                   formControlName="unit_amount">
                                            </epione-currency-input>
                                        </td>
                                        <td>
                                            <epione-currency-input
                                                                   epioneFormRequired
                                                                   [disabled]="true"
                                                                   [currency]="currency"
                                                                   [ngModel]="getLineAmount(control)"
                                                                   [ngModelOptions]="{standalone: true}">
                                            </epione-currency-input>
                                        </td>
                                        <td>
                                            <button class="btn btn-outline-danger remove-basket-item"
                                                    (click)="removeLineItem(i)"
                                                    epioneLoadingState="basket-save">
                                                <span class="material-icons">delete_outline</span>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>

                            <tr class="text-center" *ngIf="!basketItemsFormArray.controls.length">
                                <td colspan="9">No Line Items To Display</td>
                            </tr>

                            <tr>
                                <td colspan="4"></td>
                                <th class="text-epione text-uppercase text-right">
                                    Total:
                                </th>
                                <td>
                                    <epione-currency-input
                                                           epioneFormRequired
                                                           [disabled]="true"
                                                           [currency]="currency"
                                                           [ngModel]="total"
                                                           [ngModelOptions]="{standalone: true}">
                                    </epione-currency-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex">
                    <button class="btn btn-primary bg-epione" (click)="addLineItem()" epioneLoadingState="basket-save">
                        + Add Line Item
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #loadingTmpl>
        <div class="">
            <loader [message]="loadingStatus"></loader>
        </div>
    </ng-template>
</div>