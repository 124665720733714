import { AgmCoreModule } from '@agm/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@epione/app-routing.module';
import { AppComponent } from '@epione/app.component';
import { AccountModule } from '@epione/modules/account/account.module';
import { DashboardModule } from '@epione/modules/dashboard/dashboard.module';
import { InvoiceModule } from '@epione/modules/invoice/invoice.module';
import { ReportsModule } from '@epione/modules/reports/reports.module';
import { ToolbarModule } from '@epione/shared/components/toolbar/toolbar.module';
import { EpioneDateFormatter } from '@epione/shared/filters/epione-date-formatter';
import { SharedModule } from '@epione/shared/shared.module';
import { environment } from '../environments/environment';
import { NgbDateAdapter, NgbDateNativeUTCAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ActiveUserService, ActiveUserServiceFactory } from './shared/services/global/active-user.service';
import { httpInterceptorProviders } from './shared/interceptors';
import { SettingsModule } from './modules/settings/settings.module';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		BrowserAnimationsModule,
		AgmCoreModule.forRoot({
			apiKey: environment.google_key,
			libraries: ['places']
		}),
		SharedModule,
		InvoiceModule,
		AccountModule,
		ToolbarModule,
		DashboardModule,
		ReportsModule,
		HttpClientModule,
		SettingsModule
	],
	providers: [
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: NgbDateParserFormatter, useClass: EpioneDateFormatter },
		{ provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter },
		httpInterceptorProviders,
		ActiveUserService,
		{ provide: APP_INITIALIZER, useFactory: ActiveUserServiceFactory, deps: [ActiveUserService], multi: true },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
