<div class="pagiantion-container container-fluid" *ngIf="pagination">
    <div class="row justify-content-md-center">
        <div class="col-auto">
            <ngb-pagination
                            [(page)]="page"
                            [pageSize]="pagination.per_page"
                            [collectionSize]="pagination.total"
                            [rotate]="rotate"
                            [maxSize]="maxSize"
                            [boundaryLinks]="boundaryLinks"></ngb-pagination>
        </div>
    </div>
</div>