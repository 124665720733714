<div class="row justify-content-center">
    <div class="col-md-6">
        <h1 class="card-title text-epione text-uppercase font-weight-bold">Search Account</h1>
    </div>
    <div class="col-md-6">
        <a class="btn btn-primary float-right" routerLink="/account/create" routerLinkActive="active" role="button">
            + Create Account
        </a>
    </div>
    <div class="col-12">
        <hr>
    </div>
    <div class="col-md-12 col-lg-10">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="date-from" class="col-sm-3 col-form-label">Date Created From</label>
                    <div class="col-sm-9">
                        <div class="input-group mb-2 mr-sm-2">
                            <input type="text" class="form-control"
                                   epioneLoadingState="invoice-search"
                                   ngbDatepicker
                                   #fromDate="ngbDatepicker"
                                   id="date-from"
                                   [(ngModel)]="filterStartDate">
                            <div class="input-group-prepend" (click)="fromDate.toggle()">
                                <div class="input-group-text">
                                    <span class="material-icons">calendar_today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="date-to" class="col-sm-3 col-form-label">Date Created To</label>
                    <div class="col-sm-9">
                        <div class="input-group mb-2 mr-sm-2">
                            <input type="text" class="form-control"
                                   epioneLoadingState="invoice-search"
                                   ngbDatepicker
                                   #toDate="ngbDatepicker"
                                   id="date-to"
                                   [(ngModel)]="filterEndDate">
                            <div class="input-group-prepend" (click)="toDate.toggle()">
                                <div class="input-group-text">
                                    <span class="material-icons">calendar_today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="account-number" class="col-sm-3 col-form-label">Account Number</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="filters.account_number"
                               id="account-number">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="member-number" class="col-sm-3 col-form-label">Member Number</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="filters.medical_aid_number"
                               id="member-number">
                    </div>
                </div>
            </div>
            <!-- Hidden Until Searchable ID/Passport resolved -->
            <!-- <div class="col-md-6">
                <div class="form-group row">
                    <label for="id-number" class="col-sm-3 col-form-label">ID Number</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="filters.passport_number"
                               id="id-number">
                    </div>
                </div>
            </div> -->
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="patient-name" class="col-sm-3 col-form-label">Patient Name</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="search"
                               id="patient-name">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label for="invoice-number" class="col-sm-3 col-form-label d-flex align-items-center flew-row information-label">
                        Invoice Number
                        <span class="material-icons" placement="bottom" ngbTooltip="To search by invoice number, enter either the full invoice number (e.g. INV-0001) or enter asterisk with the last 2 digits (e.g. *01)">info</span>
                    </label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control"
                               epioneLoadingState="invoice-search"
                               [(ngModel)]="filters.invoice_number"
                               id="invoice-number">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex justify-content-end">
                <button class="btn btn-outline-primary px-5 mr-3" (click)="resetFilters()">Clear</button>
                <button class="btn btn-primary px-5" (click)="reload(1)">Search</button>
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-12">
        <table class="table table-hover mt-5">
            <thead>
                <tr class="text-epione text-uppercase">
                    <th scope="col">Patient</th>
                    <th scope="col" epioneSortable="account_number" (sort)="sort($event)">Account Number</th>
                    <th scope="col">Payment Type</th>
                    <th scope="col">Scheme</th>
                    <th scope="col">Status</th>
                    <th scope="col">Outstanding: This Patient</th>
                    <th scope="col">Outstanding: All Accounts</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="accounts; else accountsLoading">
                    <tr *ngFor="let account of accounts">
                        <th>{{account.patient?.first_name}} {{account.patient?.last_name}}</th>
                        <td>
                            {{account.account_number}}
                            <ng-container *ngIf="account.guarantor_id">
                              <br>Primary: {{ account.guarantor?.account_number }}
                            </ng-container>
                        </td>
                        <td>{{account.paymentMethod?.name}}</td>
                        <td>{{account.medicalAidOption?.medicalAidPlan?.medicalAidScheme?.name || 'N/A'}}</td>
                        <td>{{ account.accountStanding?.in_arrears ? 'In Arrears' : 'In Good Standing' }}</td>
                        <td>
                            <ng-container
                                          *ngIf="account.accountStanding && account.accountStanding?.outstanding && account.accountStanding?.currency; else noValue">
                                {{account.accountStanding.outstanding | currency: account.accountStanding.currency}}
                            </ng-container>
                            <ng-template #noValue>
                                N/A
                            </ng-template>
                        </td>
                        <td>
                            <ng-container
                                          *ngIf="account.guarantorAccountStanding
                                          && account.guarantorAccountStanding?.outstanding
                                          && account.guarantorAccountStanding?.currency; else noValue">
                                {{ account.guarantorAccountStanding?.outstanding | currency: account.guarantorAccountStanding.currency}}
                            </ng-container>
                            <ng-template #noValue>
                                N/A
                            </ng-template>
                        </td>
                        <td>
                            <div class="action-dropdown" ngbDropdown>
                                <a class="nav-link" tabindex="0" ngbDropdownToggle id="actionDropdown" role="button">
                                    &bull;&bull;&bull;
                                </a>
                                <div ngbDropdownMenu aria-labelledby="actionDropdown"
                                     class="dropdown-menu dropdown-menu-right">
                                    <!-- <a ngbDropdownItem [routerLink]="['/account/view', account.id]">View</a> -->
                                    <a ngbDropdownItem [routerLink]="['/account/update', account.id]">View/Edit</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #accountsLoading>
                    <tr>
                        <td colspan="10">
                            <p class="text-center">{{loading ? 'Loading Accounts...' : 'No Accounts To Display'}}</p>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <epione-pagination [pagination]="pagination" (change)="reload($event)"></epione-pagination>
    </div>
</div>
