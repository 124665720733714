import { Component, forwardRef, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NappiCodeModel } from '@epione/shared/models/codes/nappi.model';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { NappiHttpService } from '../../services/http/nappi-http.service';

@Component({
  selector: 'epione-select-nappi',
  templateUrl: './select-nappi.component.html',
  styleUrls: ['./select-nappi.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectNappiComponent), multi: true }
  ]
})
export class SelectNappiComponent implements OnInit, ControlValueAccessor {

  public codeSearchTerm$: Subject<string> = new Subject<string>();
  public codesLoading: boolean = false;
  public codes$!: Observable<NappiCodeModel[]>;
  public codePagination!: Pagination;
  public disabled?: boolean = false;
  private _code: NappiCodeModel | null = null;
  constructor(
    private nappiHttpService: NappiHttpService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.codes$ = concat<NappiCodeModel[]>(
      this.codeSearchTerm$.pipe(
        filter(v => !!v),
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => this.codesLoading = true),
        switchMap(term => this.nappiHttpService.list({
          params: {
            search: term,
            per_page: '10',
            include: 'nappiManufacturer',
          }
        }).pipe(
          map(res => {
            this.codePagination = res.meta.pagination;
            return res.data;
          }),
          catchError(() => of([])), // empty list on error
        ))
      )
    ).pipe(
      tap(() => this.codesLoading = false)
    );
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  get codeValue(): NappiCodeModel | null {
    return this._code;
  }

  set codeValue(val: NappiCodeModel | null) {  // this value is updated by programmatic changes
    if (val !== undefined && this._code !== val) {
      this._code = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.codeValue = value;
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  openModal(template: TemplateRef<any>) {
    this.modalService.open(template, { size: 'xl', centered: true });
  }

  closeModal(modal: NgbModalRef, event: any) {
    if (modal && event !== '' && event !== null) {
      modal.close('done')
    }
  }
}
