import { LineItemTypeId } from '@epione/shared/types/line-item-types';
import { BillablePresetItem } from './billable-preset.service';

const baseItem: BillablePresetItem = {
    line_item_type_id: null,
    tariff_code_txt: null,
    // tariff_code_id: '', // pending procedure codes
    icd10_codes: [],
    nappi_code_id: null,
    description: '',
    quantity: 1,
    unit_amount: 0,
};

export enum BillablePreset {
    CONSULTATION_15 = '0190',
    CONSULTATION_30 = '0191',
    CONSULTATION_45 = '0192',
    CONSULTATION_60 = '0193',
    TELEPHONIC = '0130'
};

// NB: This should be replaced by baskets in future when they are implemented and we can fetch them
export const BILLABLE_PRESETS: { [key: string]: BillablePresetItem[] } = {
    // add basic consult presets, these are all common so we generate them form the codes
    ...[
        BillablePreset.CONSULTATION_15,
        BillablePreset.CONSULTATION_30,
        BillablePreset.CONSULTATION_45,
        BillablePreset.CONSULTATION_60,
        BillablePreset.TELEPHONIC
    ].reduce((consults, code) => ({
        ...consults,
        [code]: [{ ...baseItem, line_item_type_id: LineItemTypeId.PROCEDURE, tariff_code_txt: code }]
    }), {})

    /*
     * Add in preset line items based on registered preset alias eg:
     *  
     * [BillablePreset.EXAMPLE]: [
     *   { ...baseItem, line_item_type_id: LineItemTypeId.PROCEDURE, tariff_code_txt: 'example1' },
     *   { ...baseItem, line_item_type_id: LineItemTypeId.PROCEDURE, tariff_code_txt: 'example2' }
     * ]
     */
};