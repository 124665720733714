import { Injectable } from '@angular/core';
import { LineItemTypeModel } from '@epione/shared/models/line-item-type.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';

@Injectable({
  providedIn: 'root'
})
export class LineItemTypeHttpService extends BillingHttpApiService<LineItemTypeModel, PaginatedResponse<LineItemTypeModel>> {
  protected resourcePath = "codes/line-item-types";
}
