<div class="modal-body">
  <div class="text-center">
    <img src="./assets/images/dialogs/success-image.svg ">
  </div>
  <h3 class="title-text">{{ title ? title : 'Success!' }}</h3>
  <div class="message-text">
    {{message}}
  </div>
</div>
<div class="modal-footer">
  <div class="container-fluid text-center">
    <ng-container *ngIf="actions.length; else noActions">
      <button type="button"
              class="btn btn-default mr-1 ml-1"
              *ngFor="let action of actions"
              (click)="callAction(action.action)">{{ action.title }}</button>
    </ng-container>
    <ng-template #noActions>
      <button type="button"
              class="btn btn-default"
              *ngIf="!actions.length"
              (click)="activeModal.close()">DONE</button>
    </ng-template>
  </div>
</div>