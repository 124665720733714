import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyModel } from '../models/currency.model';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: unknown, currency: CurrencyModel, ...args: unknown[]): unknown {
    if (!currency) {
      throw new Error('No Currency Provided For Currency Pipe');
    }
    // @TODO: Do we need to base these off currency arg?
    const DIVISOR = 100, DECIMAL = 2;
    const qty = parseFloat(`${value}`) / DIVISOR;
    return `${currency.alphabetic_code} ${qty.toFixed(DECIMAL)}`;
  }

}
