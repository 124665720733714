import { Injectable } from '@angular/core';
import { XeroBrandingThemeModel } from '@epione/shared/models/xero/xero-branding-theme.model';
import { HttpOptions } from '@epione/shared/services/abstract/http/http-api.service';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class XeroBrandingThemesHttpService extends BillingHttpApiService<XeroBrandingThemeModel, XeroBrandingThemeModel[]> {
  protected resourcePath = "xero/branding-themes";

  public list(options?: HttpOptions, refresh?: boolean): Observable<XeroBrandingThemeModel[]> {
    if (refresh) {
      options = {
        ...options,
        params: {
          ...(options ? options.params : {}),
          refresh: 'true'
        }
      }
    }
    return this.cachedResponse<XeroBrandingThemeModel[]>('xero-branding-themes', () => super.list(options), refresh);
  }
}
