<div class="modal-header">
    <h4 class="modal-title mb-0 text-primary">Allocate Payment to: {{ invoice.invoice_number }}</h4>
</div>
<div class="modal-body">
    <div class="container-fluid" [formGroup]="paymentForm">
        <ng-container *ngIf="paymentType;else typeSelection">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Amount Outstanding:</label><br>
                        <label>&nbsp;{{ invoice.amount_due | currency: invoice.currency }}</label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="paymentType === 'eft'">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="invoice-reference">Pending EFT Note:</label>
                            <textarea type="text" class="form-control"
                                      epioneLoadingState="payment-track"
                                      id="invoice-reference"
                                      epioneFormRequired
                                      formControlName="reference">
                            </textarea>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="paymentType !== 'eft'">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="payment_date">Payment Date:</label>
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       epioneLoadingState="payment-track"
                                       ngbDatepicker
                                       #paymentDatePicker="ngbDatepicker"
                                       id="invoice-date"
                                       epioneFormRequired
                                       formControlName="payment_date">
                                <div class="input-group-prepend" (click)="paymentDatePicker.toggle()">
                                    <div class="input-group-text">
                                        <span class="material-icons">calendar_today</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="payment-amount">Amount Paid:</label>
                            <epione-currency-input
                                                   epioneFormRequired
                                                   epioneLoadingState="payment-track"
                                                   id="payment-amount"
                                                   [currency]="invoice.currency"
                                                   formControlName="amount">
                            </epione-currency-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="invoice-note">
                                Payment Note - (eg: Receipt Number / Reference - optional):
                            </label>
                            <input type="text" class="form-control"
                                   epioneLoadingState="payment-track"
                                   id="invoice-note"
                                   epioneFormRequired
                                   formControlName="note">
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #typeSelection>
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary btn-payment-type btn-card"
                            (click)="paymentForm.get('type')?.patchValue('card')">
                        <div class="icon-wrapper">
                            <mat-icon [inline]="true">payment</mat-icon>
                        </div>
                        <div>CARD</div>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-primary btn-payment-type btn-cash"
                            (click)="paymentForm.get('type')?.patchValue('cash')">
                        <div class="icon-wrapper">
                            <mat-icon [inline]="true">local_atm</mat-icon>
                        </div>
                        <div>CASH</div>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-primary btn-payment-type btn-eft"
                            (click)="paymentForm.get('type')?.patchValue('eft')">
                        <div class="icon-wrapper">
                            <mat-icon [inline]="true">account_balance_wallet</mat-icon>
                        </div>
                        <div>EFT</div>
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<div class="modal-footer">
    <div class="row justify-content-end">
        <div class="col-auto">
            <button type="button" class="btn btn-outline-primary mx-2"
                    (click)="cancel()"
                    epioneLoadingState="payment-track">Cancel</button>
            <button type="button" class="btn btn-primary mx-2"
                    (click)="submitPayment()"
                    *ngIf="paymentType"
                    epioneLoadingState="payment-track">Submit</button>
        </div>
    </div>
</div>