import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from '@epione/modules/reports/reports.component';
import { AuthGuard } from '@epione/shared/guards/auth.guard';

const routes: Routes = [
	{
		path: 'reports',
		component: ReportsComponent,
		canActivate: [AuthGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReportsRoutingModule { }
