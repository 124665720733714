import { Injectable } from '@angular/core';
import { CaseModel } from '@epione/shared/models/main/case.model';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';
import { MainHttpApiService } from '../http/main-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class CaseHttpService extends MainHttpApiService<CaseModel, PaginatedResponse<CaseModel>> {
    protected resourcePath: string = 'cases';
}
