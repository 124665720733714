import { Injectable } from '@angular/core';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';
import { AccountModel } from '@epione/shared/models/account.model';
import { HttpClient } from '@angular/common/http';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpOptions } from '@epione/shared/services/abstract/http/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsGuarantorHttpService extends BillingHttpApiService<AccountModel> {
  protected resourcePath = 'accounts'

  constructor(protected http: HttpClient, protected errorDialogService: ErrorDialogService) {
    super(http, errorDialogService);
  }

  /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public associate(accountId: number, payload: { account_number: string, mobile_number: string, confirmation?: boolean }): Observable<AccountModel> {
    return this.http.patch<AccountModel>(this.buildAction('{:accountId}/guarantor', { accountId }), payload, this.buildOptions({})).pipe(
        catchError(err => {
          return throwError(this.handleValidationErrors(err));
        })
    );
  }

  /**
   * @param {*} body
   * @param {HttpOptions} options
   * @returns
   * @memberof HttpApiService
   */
  public removeAssociation(accountId: number): Observable<{message: string}> {
    return this.http.delete<{message: string}>(this.buildAction('{:accountId}/guarantor', { accountId }), this.buildOptions({})).pipe(
        catchError(err => {
          return throwError(this.handleValidationErrors(err));
        })
    );
  }

}
