import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolbarModule } from '@epione/shared/components/toolbar/toolbar.module';
import { FormRequiredDirective } from '@epione/shared/directives/form-required.directive';
import { LoadingStateDirective } from '@epione/shared/directives/loading-state.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddressPickerComponent } from './components/address-picker/address-picker.component';
import { DialogsModule } from "@epione/shared/dialogs/dialogs.module";
import { LoaderComponent } from './components/loader/loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe } from './pipes/currency.pipe';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { SortableDirective } from './directives/sortable.directive';
import { InvoiceStatusDirective } from './directives/invoice-status.directive';

@NgModule({
	declarations: [
		LoadingStateDirective,
		FormRequiredDirective,
		AddressPickerComponent,
		LoaderComponent,
		PaginationComponent,
		CurrencyPipe,
		CurrencyInputComponent,
		SortableDirective,
		InvoiceStatusDirective
	],
	exports: [
		LoadingStateDirective,
		FormRequiredDirective,
		AddressPickerComponent,
		LoaderComponent,
		PaginationComponent,
		CurrencyPipe,
		CurrencyInputComponent,
		MatIconModule,
		SortableDirective,
		InvoiceStatusDirective
	],
	imports: [
		CommonModule,
		ToolbarModule,
		ReactiveFormsModule,
		NgSelectModule,
		DialogsModule,
		NgbPaginationModule,
		MatIconModule,
		FormsModule
	]
})
export class SharedModule { }
