import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalAction } from "@epione/shared/dialogs/success-dialog.service";

@Component({
  selector: 'epione-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {
  public title?: string;
  public message?: string;
  public actions: SuccessModalAction[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  callAction(action: () => any): void {
    action();
    this.activeModal.close();
  }
}
