import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDialogService } from '@epione/shared/dialogs/error-dialog.service';
import { AccountMappingModel } from '@epione/shared/models/account-mapping.model';
import { BillingHttpApiService } from '@epione/shared/services/http/billing-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountMappingHttpService extends BillingHttpApiService<AccountMappingModel, AccountMappingModel[]> {
  protected resourcePath = "settings/account-mappings";
  constructor(
    protected http: HttpClient,
    protected errorDialogService: ErrorDialogService
  ) {
    super(http, errorDialogService);
  }
}
