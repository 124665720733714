import { Injectable } from '@angular/core';
import { PatientModel } from '@epione/shared/models/main/patient.model';
import { MainHttpApiService } from '@epione/shared/services/http/main-http-api.service';
import { PaginatedResponse } from '@epione/shared/types/paginatedResponse';

@Injectable({
    providedIn: 'root'
})
export class PatientHttpService extends MainHttpApiService<PatientModel, PaginatedResponse<PatientModel>> {
    protected resourcePath: string = 'patients';
}
