export enum LineItemTypeAlias {
    CONSUMABLE = 'consumable',
    PROCEDURE = 'procedure',
    MODIFIER = 'modifier',
    MEDICINE = 'medicine',
    META_MEDICAL_AID = 'meta_medical_aid',
}

export enum LineItemTypeId {
    CONSUMABLE = 1,
    PROCEDURE = 2,
    MODIFIER = 3,
    MEDICINE = 4,
    META_MEDICAL_AID = 5,
}