import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountListComponent } from '@epione/modules/account/account-list/account-list.component';
import { AccountSaveComponent } from '@epione/modules/account/account-save/account-save.component';
import { AccountViewComponent } from '@epione/modules/account/account-view/account-view.component';
import { AccountComponent } from '@epione/modules/account/account.component';
import { AuthGuard } from "@epione/shared/guards/auth.guard";

const routes: Routes = [
	{
		path: 'account',
		component: AccountComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', redirectTo: '/list', pathMatch: 'full' },
			{
				path: 'list',
				component: AccountListComponent
			}, {
				path: 'create',
				component: AccountSaveComponent
			}, {
				path: 'update/:id',
				component: AccountSaveComponent
			}
			// No Mockups for View specific screen, can see details on edit screen
			/* , {
				path: 'view/:id',
				component: AccountViewComponent
			} */
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AccountRoutingModule { }
