import { Injectable } from '@angular/core';
import { BillingHttpApiService } from "@epione/shared/services/http/billing-http-api.service";
import { MedicalAidPracticeModel } from "@epione/shared/models/medical-aid-practice.model";
import { PaginatedResponse } from "@epione/shared/types/paginatedResponse";

@Injectable({
  providedIn: 'root'
})
export class MedicalAidPracticeService extends BillingHttpApiService<MedicalAidPracticeModel, PaginatedResponse<MedicalAidPracticeModel>> {
  protected resourcePath = "settings/medical-aid-practice";
}
