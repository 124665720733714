import { Component, forwardRef, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICD10CodeModel } from '@epione/shared/models/codes/icd10.model';
import { Pagination } from '@epione/shared/types/paginatedResponse';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { Icd10HttpService } from '../../services/http/icd10-http.service';

@Component({
  selector: 'epione-select-icd10',
  templateUrl: './select-icd10.component.html',
  styleUrls: ['./select-icd10.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectIcd10Component), multi: true }
  ]
})
export class SelectIcd10Component implements OnInit, ControlValueAccessor {

  public codeSearchTerm$: Subject<string> = new Subject<string>();
  public codesLoading: boolean = false;
  public codes$!: Observable<ICD10CodeModel[]>;
  public codePagination!: Pagination;
  public disabled?: boolean;
  private _codes: ICD10CodeModel[] | null = null;
  constructor(
    private icd10HttpService: Icd10HttpService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.codes$ = concat<ICD10CodeModel[]>(
      this.codeSearchTerm$.pipe(
        filter(v => !!v),
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => this.codesLoading = true),
        switchMap(term => this.icd10HttpService.list({
          params: {
            search: term,
            per_page: '10',
            include: 'icd103Code.icd10Group.icd10Chapter'
          }
        }).pipe(
          map(res => {
            this.codePagination = res.meta.pagination;
            console.log(res.data);
            return res.data;
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.codesLoading = false)
        ))
      )
    );
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  get codeValues(): ICD10CodeModel[] | null {
    return this._codes;
  }

  set codeValues(val: ICD10CodeModel[] | null) {  // this value is updated by programmatic changes
    if (val !== undefined && this._codes !== val) {
      this._codes = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.codeValues = value
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  openModal(template: TemplateRef<any>) {
    this.modalService.open(template, { size: 'xl', centered: true });
  }

  closeModal(modal: NgbModalRef, event: any) {
    if (modal && event !== '' && event !== null) {
      modal.close('done')
    }
  }
}
