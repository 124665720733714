import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Interceptor to strip skip headers on requests
 *
 * @export
 * @class SanitizeInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class SanitizeInterceptor implements HttpInterceptor {

    /**
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof SanitizeInterceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // clear all skip headers
        return next.handle(req.clone({
            headers: this.getCleanedHeaders(req)
        }));
    }

    /**
     * identify the headers to be stripped from the request
     *
     * @param {HttpRequest<any>} req
     * @returns
     * @memberof SanitizeInterceptor
     */
    getCleanedHeaders(req: HttpRequest<any>): HttpHeaders {
        return req.headers.keys()
            .filter(key => key.substr(0, 6) === 'Skip--')
            .reduce((headers, key) => headers.delete(key), req.headers);
    }
}
