<ng-container *ngIf="loaded; else loading">
    <div class="modal-header">
        <h4 class="modal-title mb-0 text-primary">Send Invoice {{ invoice?.invoice_number}} Now</h4>
    </div>
    <div class="modal-body text-center">
        <div *ngIf="!invoice?.sent">
            <p>
                This invoice has been authorized and is ready to send.
                <br>
                <br>
                Do you wish to send this invoice to the patient now?
            </p>
        </div>
        <p *ngIf="invoice?.sent">
            <strong>NOTICE:</strong> This invoice has already been sent.
            <br><br>
            Do you wish to re-send this invoice to the patient now?
        </p>
        <div *ngIf="showClaimsSection" class="mb-3">
            <p>
                <input type="checkbox"
                       class="mt-2 mb-1 mr-3"
                       id="claim-email-checkbox"
                       epioneLoadingState="invoice-send"
                       [(ngModel)]="sendClaimEmail">
                <label for="claim-email-checkbox">Send to medical aids claim email ({{ claimEmail }})</label>
            </p>
            <div class="claim-email-note">
                <b>*Note:</b> Applies to both Print and Send Now option
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <button type="button" class="btn btn-outline-primary col" (click)="modal.close()"
                        epioneLoadingState="invoice-send">
                    Cancel
                </button>
            </div>
            <div class="col-4">
                <button type="button" class="btn btn-outline-primary col"
                        (click)="invoiceAction$.next({ action: 'print', sendClaimEmail: sendClaimEmail })"
                        epioneLoadingState="invoice-send">
                    Print
                </button>
            </div>
            <div class="col-4">
                <button type="button" class="btn btn-primary col"
                        (click)="invoiceAction$.next({ action: 'send', sendClaimEmail: sendClaimEmail })"
                        epioneLoadingState="invoice-send">
                    Send Now
                </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div>
        <loader [message]="'Checking Medical Aid Claim Email'"></loader>
    </div>
</ng-template>