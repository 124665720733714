import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SuccessDialogService {

  constructor(private modalService: NgbModal) { }

  public showSuccessDialog(message: string, title: string = '', actions: SuccessModalAction[] = []): NgbModalRef {
    const modalRef = this.modalService.open(SuccessDialogComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.actions = actions;

    return modalRef;
  }
}

export interface SuccessModalAction {
  title: string;
  action: () => void;
}