import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PracticeMemberModel } from '@epione/shared/models/main/practice-member.model';
import { MemberPreferencesModel } from '@epione/shared/models/member-preferences.model';
import { XeroBrandingThemeModel } from '@epione/shared/models/xero/xero-branding-theme.model';
import { ActiveUserService } from '@epione/shared/services/global/active-user.service';
import { LoadingStateService } from '@epione/shared/services/global/loading-state.service';
import { PracticeMemberHttpService } from '@epione/shared/services/main/practice-member.service';
import { forkJoin } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { MemberPreferencesHttpService } from './services/http/member-preferences-http.service';
import { XeroBrandingThemesHttpService } from './services/http/xero-branding-themes-http.service';

interface PreferencesObj {
  member: PracticeMemberModel;
  exists: boolean;
  form: FormGroup;
  loading?: boolean;
}

@Component({
  selector: 'epione-member-preferences',
  templateUrl: './member-preferences.component.html',
  styleUrls: ['./member-preferences.component.scss']
})
export class MemberPreferencesComponent implements OnInit {
  public loadingStatus: string | null = 'Loading Practice Members';
  public memberForms: PreferencesObj[] = [];
  public xeroBrandingThemes: XeroBrandingThemeModel[] = [];
  public visibleProviders: { [key: string]: boolean } = {
    xero: true
  };

  constructor(
    private fb: FormBuilder,
    private practiceMemberHttpService: PracticeMemberHttpService,
    private xeroBrandingThemesHttpService: XeroBrandingThemesHttpService,
    private memberPreferencesHttpService: MemberPreferencesHttpService,
    private activeUserService: ActiveUserService,
    private loadingService: LoadingStateService
  ) { }

  ngOnInit(): void {
    this.loadMembers();
  }

  loadMembers() {
    this.visibleProviders[this.activeUserService.integration?.integrationType.alias as string] = true;
    forkJoin([
      this.practiceMemberHttpService.listAll(this.activeUserService.practiceId as number, {
        params: {
          'filter[type]': 'practitioners',
          'filter[authorized]': 'true'
        }
      }),
      this.memberPreferencesHttpService.list(),
      this.loadBrandingThemes()
    ]).pipe(
      take(1),
      finalize(() => this.loadingStatus = null),
      tap(([members, settings, _]) => {
        members.sort(
          // sort alphabetically by name for ease of use
          (a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0)
        ).forEach(member => {
          this.appendMemberForm(member, settings.find(({ user_id }) => user_id === member.id));
        })
      })
    ).subscribe();
  }

  loadBrandingThemes(refresh: boolean = false, prefs?: PreferencesObj) {
    if (prefs) {
      prefs.loading = true;
    }
    const obs = this.xeroBrandingThemesHttpService.list(undefined, refresh).pipe(
      tap(themes => this.xeroBrandingThemes = themes),
      tap(() => {
        if (prefs) {
          prefs.loading = false;
        }
      })
    );

    if (prefs) {
      obs.subscribe();
    }
    return obs;
  }

  appendMemberForm(member: PracticeMemberModel, settings?: MemberPreferencesModel) {
    this.memberForms.push({
      member,
      exists: !!settings,
      form: this.fb.group({
        user_id: [member.id],
        xero_branding_theme_guid: [settings ? settings.xero_branding_theme_guid : null],
        // member information, only for display will be stripped out on save
      })
    });
  }

  savePreferences(preferences: PreferencesObj) {
    const obs = preferences.exists
      ? () => this.memberPreferencesHttpService.update(preferences.member.id, preferences.form.getRawValue())
      : () => this.memberPreferencesHttpService.create(preferences.form.getRawValue());

    this.loadingService.start('member-pref-' + preferences.member.id);
    obs().pipe(
      take(1),
      finalize(() => this.loadingService.end('member-pref-' + preferences.member.id))
    ).subscribe({
      next: () => {
        // will always exist now regardless of if it was create/update prior.
        preferences.exists = true;
      }
    });
  }

}
