export enum CaseStageAlias {
    APPOINTMENT_SCHEDULE = 'appointment_schedule',
    APPOINTMENT_PERFORM = 'appointment_perform',
    CONSULTATION_SCHEDULE = 'consultation_schedule',
    CONSULTATION_PERFORM = 'consultation_perform',
    PROCEDURE_SCHEDULE = 'procedure_schedule',
    SHARE_RESULTS = 'share_results',
    FOLLOW_UP_MEETING_SCHEDULE = 'follow_up_meeting_schedule',
    FOLLOW_UP_MEETING_PERFORM = 'follow_up_meeting_perform',
    EXIT_MEETING_SCHEDULE = 'exit_meeting_schedule',
    EXIT_MEETING_PERFORM = 'exit_meeting_perform',
    CASE_CLOSE = 'case_close',
    CASE_CLOSE_AFTER_CONSULTATION = 'case_close_after_consultation',
    CASE_CLOSE_AFTER_FOLLOW_UP = 'case_close_after_follow_up',
    CASE_CLOSE_AFTER_APPOINTMENT = 'case_close_after_appointment',
    LAB_TEST_SCHEDULE = 'lab_test_schedule',
    LAB_TEST_PERFORM = 'lab_test_perform',
    PATH_CARE_PRE_REG_SCHEDULE = 'path_care_pre_reg_schedule',
    PATH_CARE_PRE_REG_PERFORM = 'path_care_pre_reg_perform',
    RELEASE_TO_WARD = 'release_to_ward',
    COVID_19_TEST_REFERRAL = 'covid_19_test_referral',
    PROCEDURE_PERFORM = 'procedure_perform',
    PRE_OP_ADMISSION = 'pre_op_admission',
    DISCHARGE = 'discharge',
    LANCET_TEST_SCHEDULE = 'lancet_test_schedule',
    LANCET_TEST_PERFORM = 'lancet_test_perform',
    CASE_CREATE = 'case_create',
    PRIMARY_NURSE_PRE_ASSESSMENT = 'primary_nurse_pre_assessment',
    PRIMARY_NURSE_CONSULT_SCHEDULE = 'primary_nurse_consult_schedule',
    PRIMARY_NURSE_CONSULT_PERFORM = 'primary_nurse_consult_perform',
    PRIMARY_NURSE_FOLLOW_UP_1_SCHEDULE = 'primary_nurse_follow_up_1_schedule',
    PRIMARY_NURSE_FOLLOW_UP_1_PERFORM = 'primary_nurse_follow_up_1_perform',
    PRIMARY_NURSE_FOLLOW_UP_2_SCHEDULE = 'primary_nurse_follow_up_2_schedule',
    PRIMARY_NURSE_FOLLOW_UP_2_PERFORM = 'primary_nurse_follow_up_2_perform',
    PRIMARY_NURSE_FOLLOW_UP_3_SCHEDULE = 'primary_nurse_follow_up_3_schedule',
    PRIMARY_NURSE_FOLLOW_UP_3_PERFORM = 'primary_nurse_follow_up_3_perform',
    PRIMARY_NURSE_FOLLOW_UP_4_SCHEDULE = 'primary_nurse_follow_up_4_schedule',
    PRIMARY_NURSE_FOLLOW_UP_4_PERFORM = 'primary_nurse_follow_up_4_perform',
    PRIMARY_NURSE_FOLLOW_UP_5_SCHEDULE = 'primary_nurse_follow_up_5_schedule',
    PRIMARY_NURSE_FOLLOW_UP_5_PERFORM = 'primary_nurse_follow_up_5_perform',
    PRIMARY_NURSE_FOLLOW_UP_6_SCHEDULE = 'primary_nurse_follow_up_6_schedule',
    PRIMARY_NURSE_FOLLOW_UP_6_PERFORM = 'primary_nurse_follow_up_6_perform',
    PRIMARY_NURSE_FOLLOW_UP_7_SCHEDULE = 'primary_nurse_follow_up_7_schedule',
    PRIMARY_NURSE_FOLLOW_UP_7_PERFORM = 'primary_nurse_follow_up_7_perform',
    PRIMARY_NURSE_FOLLOW_UP_8_SCHEDULE = 'primary_nurse_follow_up_8_schedule',
    PRIMARY_NURSE_FOLLOW_UP_8_PERFORM = 'primary_nurse_follow_up_8_perform',
    PRIMARY_NURSE_FOLLOW_UP_9_SCHEDULE = 'primary_nurse_follow_up_9_schedule',
    PRIMARY_NURSE_FOLLOW_UP_9_PERFORM = 'primary_nurse_follow_up_9_perform',
    PRIMARY_NURSE_FOLLOW_UP_10_SCHEDULE = 'primary_nurse_follow_up_10_schedule',
    PRIMARY_NURSE_FOLLOW_UP_10_PERFORM = 'primary_nurse_follow_up_10_perform',
    CLOSE_AFTER_NURSE_CONSULTATION = 'case_close_after_nurse_consultation',
    CLOSE_AFTER_NURSE_FOLLOW_UP = 'case_close_after_nurse_follow_up'
}
export enum CaseStageId {
    'appointment_schedule' = 1,
    'appointment_perform' = 2,
    'consultation_schedule' = 3,
    'consultation_perform' = 4,
    'procedure_schedule' = 5,
    'share_results' = 9,
    'follow_up_meeting_schedule' = 10,
    'follow_up_meeting_perform' = 11,
    'exit_meeting_schedule' = 12,
    'exit_meeting_perform' = 13,
    'case_close' = 14,
    'case_close_after_consultation' = 15,
    'case_close_after_follow_up' = 16,
    'case_close_after_appointment' = 17,
    'lab_test_schedule' = 18,
    'lab_test_perform' = 19,
    'path_care_pre_reg_schedule' = 20,
    'path_care_pre_reg_perform' = 21,
    'release_to_ward' = 22,
    'covid_19_test_referral' = 23,
    'procedure_perform' = 7,
    'pre_op_admission' = 6,
    'discharge' = 8,
    'lancet_test_schedule' = 24,
    'lancet_test_perform' = 25,
    'case_create' = 26,
    'primary_nurse_pre_assessment' = 27,
    'primary_nurse_consult_schedule' = 28,
    'primary_nurse_consult_perform' = 29,
    'primary_nurse_follow_up_1_schedule' = 30,
    'primary_nurse_follow_up_1_perform' = 31,
    'primary_nurse_follow_up_2_schedule' = 32,
    'primary_nurse_follow_up_2_perform' = 33,
    'primary_nurse_follow_up_3_schedule' = 34,
    'primary_nurse_follow_up_3_perform' = 35,
    'primary_nurse_follow_up_4_schedule' = 36,
    'primary_nurse_follow_up_4_perform' = 37,
    'primary_nurse_follow_up_5_schedule' = 38,
    'primary_nurse_follow_up_5_perform' = 39,
    'primary_nurse_follow_up_6_schedule' = 40,
    'primary_nurse_follow_up_6_perform' = 41,
    'primary_nurse_follow_up_7_schedule' = 42,
    'primary_nurse_follow_up_7_perform' = 43,
    'primary_nurse_follow_up_8_schedule' = 44,
    'primary_nurse_follow_up_8_perform' = 45,
    'primary_nurse_follow_up_9_schedule' = 46,
    'primary_nurse_follow_up_9_perform' = 47,
    'primary_nurse_follow_up_10_schedule' = 48,
    'primary_nurse_follow_up_10_perform' = 49,
    'case_close_after_nurse_consultation' = 50,
    'case_close_after_nurse_follow_up' = 51
}

export enum CaseStageStatusAlias {
    TO_DO = 'to_do',
    IN_PROGRESS = 'in_progress',
    SCHEDULED = 'scheduled',
    PERFORMED = 'performed',
    PRE_ADMISSION = 'pre_admission',
    ADMISSION = 'admission',
    DISCHARGE_DONE = 'discharge_done',
    SHARED = 'shared',
    CLOSED = 'closed',
    NOT_APPLICABLE = 'not_applicable',
    RELEASED = 'released',
}
export enum CaseStageStatusAlias {
    'to_do' = 1,
    'in_progress' = 2,
    'scheduled' = 3,
    'performed' = 4,
    'pre_admission' = 5,
    'admission' = 6,
    'discharge_done' = 7,
    'shared' = 8,
    'closed' = 9,
    'not_applicable' = 10,
    'released' = 11,
}